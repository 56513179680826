// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../UseNotification';
import Api from '../../contexts/Api';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { getValue, prepareFormData, formatDate } from '../../contexts/Utils';
import Formfields from '../Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetailDrivers = (props) => {
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const { notification } = useNotification();
  const navigate = useNavigate();
  const mission = props.mission;
  const classes = useStyle();
  const [users, setUsers] = useState([])

  // 
  // ─── GET DRIVERS PROPOSALS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getUsersViews = await Api({ endpoint: `/missions/drivers_proposal?mission_id=${props.mission_id}`, method: 'GET', });
      setUsers(getUsersViews.data);
    }

    fetchData();
  }, [props.mission_id])

  // 
  // ─── CHOOSE DRIVER ───────────────────────────────────────
  //
  const handleMissionDriver = async () => {
    const data = prepareFormData({ formId: 'code_form' });
    if (data.errors) {
      setErrors(data.errors);
      return;
    }

    const selectedProposal = users.filter((u) => u.id === selectedId)[0];

    let paramsData = {
      fields: {
        delivery_date: formatDate({ time: selectedProposal.delivery_date, display: 'mission' }),
        recovery_date: formatDate({ time: selectedProposal.recovery_date, display: 'mission' }),
        weproov_code: data.fields.weproov_code,
        driver_id: selectedProposal.user_id
      },
      company_id: props.mission.company_id,
      select_driver: true,
      uuid: props.mission_id,
      from_detail: true,
    }

    if (selectedProposal.recovery_delivery_date) {
      paramsData.fields.recovery_delivery_date = formatDate({ time: selectedProposal.recovery_delivery_date, display: 'mission' })
    }

    if (props.mission.recovery) {
      paramsData.recovery_weproov_code = data.fields.recovery_weproov_code;
    }

    const params = { endpoint: `/missions`, method: 'PUT', data: paramsData };

    const createMission = await Api(params);
    notification({ variant: createMission.success ? 'success' : 'error', message: "Convoyeur sélectionné pour la mission" });
    navigate('/');
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(users.length) && <div className={classes.container}>
    <Modal
      open={codeModalOpen}
      onClose={() => setCodeModalOpen(false)}
    >
      <Box className={classes.codeModal}>
        <h2>Code weproov</h2>
        <form id='code_form' className={classes.weproovModal}>
          <Formfields field={{ type: 'text', component: 'text', name: 'weproov_code', label: 'Code weproov', required: true, error: getValue(errors, ['weproov_code', 'message']) }} />
          {Boolean(props.mission.recovery) && <Formfields field={{ type: 'text', component: 'text', name: 'recovery_weproov_code', label: 'Code weproov reprise', required: true, error: getValue(errors, ['recovery_weproov_code', 'message']) }} />}
          <Button variant="contained" onClick={handleMissionDriver}> Valider le convoyeur</Button>
        </form>
      </Box>
    </Modal>
    <h2>{`${mission.status > 2 ? 'Historique des convoyeurs' : 'Convoyeurs disponibles pour la mission'}`}</h2>
    <div className={classes.driversContainer}>
      {users.map((driver, index) => {
        // if (driver.user_id === mission.driver_id) return null

        return <div key={driver.id} className={classes.driverCard}>
          <span>{index + 1}</span>
          <span>{driver.firstname} {driver.name}</span>
          <span>{driver.email}</span>
          <span>Récupération : {formatDate({ time: driver.recovery_date, display: 'date' })}</span>
          <span>Livraison : {formatDate({ time: driver.delivery_date, display: 'date' })}</span>
          {driver.recovery_delivery_date && <span>Retour : {formatDate({ time: driver.recovery_delivery_date, display: 'date' })}</span>}
          {mission.status <= 2 && <Button onClick={() => { setCodeModalOpen(true); setSelectedId(driver.id); }} autoFocus variant="contained" size="small" className={classes.chooseDriver}>Choisir</Button>}
        </div>
      })}
    </div>
  </div>
};

export default MissionDetailDrivers;

//
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const useStyle = createUseStyles({
  container: {
    background: '#FFF',
    padding: 20,
    boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
    marginTop: 20
  },
  driversContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  driverCard: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    '& > span:nth-child(5)': {
      textTransform: 'capitalize'
    }
  },
  codeModal: {
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '25px',
    width: '350px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& h2': {
      width: '100%',
      textAlign: 'center',
      marginBottom: "35px",
      marginTop: '0',
      alignSelf: "center"
    }
  },
  modalInput: {
    width: '150px',
    marginBottom: '10px'
  },
  weproovModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  chooseDriver: {
    marginLeft: 'auto'
  }
});