/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getValue } from '../contexts/Utils';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IconButton from '@mui/material/IconButton';

//
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (opt) => {
  const field = opt.field;
  const defaultValue = getValue(field, ['default']) || field.value || '';
  const dataSet = {};

  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  Object.entries(field.dataSet || {}).map(([key, value]) => (dataSet[key] = value));

  let textField = (
    <>
      <TextField
        multiline={field.multiline}
        rows={field.rows ? 6 : 1}
        InputLabelProps={{ shrink: true }}
        label={field.label}
        type={field.type}
        name={field.name}
        value={value}
        onChange={handleChange}
        inputProps={{
          ...dataSet,
        }}
        error={field.error ? true : false}
        required={field.required}
        size="small"
        helperText={field.error}
        placeholder={field.placeholder}
      />
      {'help' in field && field.help}
    </>
  );
  return textField;
};

//
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {
  const field = opt.field;
  const defaultValue =
    getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0;
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={'value' in field ? Boolean(field.value) : selected ? true : false}
            defaultChecked={field.defaultChecked}
            disabled={field.disabled}
            value={selected}
            onChange={() => {
              setSelected(selected === 0 ? 1 : 0);
              field.onChange && field.onChange(selected === 0 ? 1 : 0, field.name);
            }}
            name={field.name}
          />
        }
        label={field.label || ''}
      />
      {
        Boolean(field.textfield && selected) && (
          <TextField
            type='text'
            name={`${field.textName}`}
            required={true}
            size="small"
            InputLabelProps={{ shrink: true }}
            label={field.textLabel}
            sx={{ marginTop: '10px' }}
            helperText={field.textError}
            error={field.textError ? true : false}
            defaultValue={field.textValue || ''}
          />
        )
      }
    </>
  );
};

//
// ─── RADIO ───────────────────────────────────────
//
export const RadioField = (opt) => {
  const field = opt.field;
  const defaultValue =
    getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{field.label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          name={field.name}
          onChange={handleChange}
        >
          {field.value.map((radio) => (
            <FormControlLabel className={field.class ? field.class : ''} value={radio.value} control={<Radio />} label={radio.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

//
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (opt) => {
  const field = opt.field;
  const defaults = getValue(field, ['default']);

  // Define the name and value of select items
  const dataLabel = field.dataLabel || 'name';
  const dataValue = field.dataValue || 'id';

  const [select, selectValue] = useState(defaults);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value);
    }
  };

  useEffect(() => {
    if (defaults && select !== defaults) {
      selectValue(defaults);
    }
  }, [defaults]);

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id="simple-select-label" shrink>
        {field.label}
      </InputLabel>
      <Select
        value={select}
        input={
          <OutlinedInput notched name={field.name} id="simple-select-label" label={field.label} />
        }
        onChange={handleChange}
      >
        {field.data.map((value) => (
          <MenuItem key={value[dataValue]} value={value[dataValue]}>
            {value[dataLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

//
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (opt) => {
  const field = opt.field;
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [checked, setChecked] = useState(field.checked || false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
    }
  });

  const classes = useStyle();

  return (
    <>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            required={field.required}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={field.name}
          />
        }
        label={field.label}
      /> */}
      <div>
        <Checkbox checked={checked} required={field.required} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} name={field.name} />
        <label for dangerouslySetInnerHTML={{ __html: field.label }} />
      </div>
      {field.error && <FormHelperText error={true}>{field.error}</FormHelperText>}
    </>
  );
};

//
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field;
  const fieldName = field.name;
  const titles = field.titles;
  const defaults = getValue(field, ['default']);

  const componentValue = getValue(field, ['components', 'value']);

  //
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {};

  if (defaults) {
    Object.values(JSON.parse(defaults)).forEach((el, index) => {
      const elementId = `${field.name}_${index}`;
      multipleFields[elementId] = [
        {
          type: 'text',
          component: 'text',
          label: titles.label,
          value: el.label,
          name: `${field.name}_label_${index}`,
        },
        {
          type: 'text',
          component: componentValue,
          label: titles.value,
          value: el.value,
          name: `${field.name}_value_${index}`,
          multiline: field.multiline,
          hidden: field.hidden,
        },
      ];
    });
  } else {
    multipleFields = {
      1: [
        { type: 'text', component: 'text', label: titles.label, name: `${fieldName}_label_1` },
        {
          type: 'text',
          component: componentValue,
          label: titles.value,
          name: `${fieldName}_value_1`,
          multiline: field.multiline,
          hidden: field.hidden,
        },
      ],
    };
  }

  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    multipleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    multipleLabel: {
      display: 'flex',
      '& > span': {
        width: '50%',
      },
    },
    multipleFields: {
      display: 'flex',
      marginTop: 20,
    },
    button: {
      marginTop: '20px !important',
    },
    inputLeft: {
      marginLeft: 5,
    },
    inputRight: {
      marginRight: 5,
    },
  });

  const classes = useStyle();

  return (
    <div>
      <label>{field.label}</label>
      <div className={classes.multipleContainer + ' form__multiple'}>
        {Object.values(fields).map((element, index) => {
          return (
            <div key={index} className={classes.multipleFields}>
              {element.map((el, i) => {
                return (
                  <Formfields
                    key={i}
                    field={{
                      ...el,
                      ...{ dataSet: { 'data-attribute': 'multiple', 'data-name': fieldName } },
                    }}
                    classNameOuter={i === 0 ? classes.inputRight : classes.inputLeft}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      {!field.removeAdd && (
        <Button
          className={classes.button}
          variant="contained"
          onClick={(event) => {
            event.preventDefault();
            let index = Object.keys(fields).length + 1;

            //
            // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
            //
            setFields(
              (fields[index] = {
                ...fields,
                [`${field.name}_${index}`]: [
                  {
                    type: 'text',
                    component: 'text',
                    label: titles.label,
                    name: `${fieldName}_label_${index}`,
                  },
                  {
                    type: 'text',
                    component: componentValue,
                    label: titles.value,
                    name: `${fieldName}_value_${index}`,
                    multiline: field.multiline,
                    hidden: field.hidden,
                  },
                ],
              })
            );
          }}
        >
          Ajouter
        </Button>
      )}
    </div>
  );
};

export const UploadFile = (opt) => {
  const field = opt.field;
  const [file, setFile] = useState();

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  let useStyle = createUseStyles({
    input: { display: 'none !important' },
    label: {
      marginBottom: 10,
    },
    error: {
      color: '#d32f2f',
      marginTop: 5,
      display: 'block',
    },
    field: {
      width: '100%',
      border: field.error ? '1px solid #d32f2f !important' : 'initial',
    },
    fileUploaded: {
      width: 'calc(50% - 10px)',
      alignItems: 'center',
    },
    fileContent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
      marginRight: 'auto',
    },
    fileIcon: {
      fontSize: '35px !important',
      color: 'var(--primary-color)',
    },
    fileLabel: {
      marginBottom: 5,
    },
    fileName: {
      fontSize: 13,
      width: 275,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  });

  const classes = useStyle();

  return (
    <>
      <div className={classes.fileUploaded} style={{ display: file ? 'flex' : 'none' }}>
        <InsertDriveFileIcon className={classes.fileIcon} />
        <div className={classes.fileContent}>
          <span className={classes.fileLabel}>{field.label}</span>
          <span className={classes.fileName}>{file}</span>
        </div>
        <IconButton
          aria-label="delete"
          onClick={() => {
            document.querySelector(`input[name='${field.name}']`).value = '';
            setFile(null);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={classes.fileContainer} style={{ display: file ? 'none' : 'block' }}>
        <span className={classes.label}>{opt.field.label}</span>
        <label htmlFor={`${field.name}-file`} className={classes.label}>
          <Input
            className={classes.input}
            accept="image/*"
            id={`${field.name}-file`}
            type="file"
            name={field.name}
            onChange={(e) => {
              if (field.handleChange) {
                field.handleChange(e)
              } else {
                setFile(e.target.files[0].name);
              }
            }}
            required={field.required ? true : false}
          />
          <Button variant="contained" component="span" className={classes.field}>
            Télécharger
          </Button>
          {field.error && <span className={classes.error}>{field.error}</span>}
        </label>
      </div>
    </>
  );
};

//
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let field = props.field;

  const [date, setDate] = useState();
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  return (
    <TextField
      label={field.label}
      type={field.type}
      size="small"
      value={date}
      defaultValue={field.filled ? now.toISOString().slice(0, 16) : field.defaultValue ? field.defaultValue : ''}
      name={field.name}
      required={field.required}
      error={field.error ? true : false}
      helperText={field.error}
      onChange={(e) => {
        setDate(e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

//
// ─── BUTTON GROUP ───────────────────────────────────────
//
const GroupedButtons = (opt) => {
  const field = opt.field;
  const [counter, setCounter] = useState(1);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    label: {
      marginRight: 10,
    },
  });

  const classes = useStyle();

  //
  // ─── HANDLE INPUT INCREMENTATION OR DECREMENT ───────────────────────────────────────
  //
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };
  return (
    <div>
      <span className={classes.label}>{field.label}</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button disabled={counter === 1} onClick={handleDecrement}>
          -
        </Button>
        <TextField
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={field.name}
          value={counter}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value <= 8) {
              setCounter(parseInt(e.target.value));
            }
          }}
          style={{ width: 40 }}
        />
        <Button disabled={counter === 8} onClick={handleIncrement}>
          +
        </Button>
      </ButtonGroup>
    </div>
  );
};
//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const SearchField = (props) => {
  const field = props.field;
  const data = field.data;

  const [value, setValue] = useState(field.multiple ? [] : field.default || '');

  useEffect(() => {
    if (field.default !== value) {
      setValue(field.default || '');
    }
  }, [field.default]);

  return (
    <>
      <Autocomplete
        id={props.name}
        size="small"
        freeSolo
        multiple={field.multiple}
        options={data}
        noOptionsText={'Aucun résultat'}
        getOptionLabel={(option) => option.label || ''}
        onInputChange={(event, newInputValue) => {
          props.field.handleSearch({
            value: newInputValue,
            name: field.name,
            ...(props.handleSearchParams || {}),
          });
        }}
        onChange={(e, inputValue) => {
          setValue(inputValue);
          if (props.field.handleChange) {
            props.field.handleChange({ name: field.name, value: inputValue });
          }
        }}
        value={value}
        className={props.className}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label={field.label}
            value={field.default}
            placeholder={field.placeholder}
          />
        )}
      />
      {value && <input type="hidden" name={field.name} value={value.id} />}
    </>
  );
};

//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const Formfields = (props) => {
  let fields;

  switch (props.field.component) {
    case 'switch':
      fields = SwitchField(props);
      break;
    case 'radio':
      fields = RadioField(props);
      break;
    case 'select':
      fields = SelectField(props);
      break;
    case 'checkbox':
      fields = CheckboxField(props);
      break;
    case 'multiple':
      fields = Multiple(props);
      break;
    case 'file':
      fields = UploadFile(props);
      break;
    case 'datepicker':
      fields = DatePicker(props);
      break;
    case 'grouped_btn':
      fields = GroupedButtons(props);
      break;
    case 'search':
      fields = SearchField(props);
      break;
    default:
      fields = Text(props);
      break;
  }

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputContainer: {
      display: props.field.hidden ? 'none' : 'flex',
      flexDirection: 'column',
      '@media screen and (max-width:780px)': {
        width: '100% !important',
      },
    },
  });

  const classes = useStyle();

  return (
    <div
      className={classes.inputContainer + ' formfield_container ' + props.classNameOuter}
      style={{ width: props.field.size || '100%' }}
    >
      {fields}
    </div>
  );
};

export default Formfields;
