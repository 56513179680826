// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { formatDate, formatDistanceTime } from '../../contexts/Utils';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { PenaltiesList } from '../../contexts/Contexts';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetailRecap = (props) => {

  let mission = props.mission

  let details = {
    distance: 'Distance totale',
    duration: 'Durée',
    vehicle: 'Véhicule',
    cleaning: 'Nettoyage',
    insurance: 'Assurance',
    detail: 'Détail',
    electric: 'Véhicule électrique',
    easy_to_use: 'Mise en main simple',
    refueling: 'Remise à niveau du carburant',
  };

  if (props.role !== 'garage' && mission.price_driver && !props.recovery) {
    details = { price_driver: props.role === 'driver' ? 'Prix' : 'Prix convoyeur', ...details };
  } else if (props.role !== 'driver' && mission.price && !props.recovery) {
    details = { price: props.role === 'garage' ? 'Prix' : 'Prix garage', ...details };
  }

  const origin_address = `${mission.origin_address}, ${mission.origin_cp} ${mission.origin_city}`;
  const destination_address = `${mission.destination_address}, ${mission.destination_cp} ${mission.destination_city}`;

  let penalties = 0;

  PenaltiesList.map((penalty) => {
    if (mission[penalty.name]) {
      penalties += mission[penalty.name];
    }
  })

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    outerContainer: {
      display: 'flex',
      gap: 20,
      marginBottom: 20,
      '@media screen and (max-width:1150px)': {
        flexDirection: 'column !important',
      },
    },
    travelContainer: {
      width: 'calc(100% - 40px)',
    },
    contactContainer: {
      display: 'flex',
      minWidth: 400,
      gap: 20,
      '@media screen and (max-width:1150px)': {
        flexDirection: 'row',
        '&>div': {
          width: '100%',
        },
      },
      '@media screen and (max-width:850px)': {
        flexDirection: 'column',
        minWidth: 300,
        '&>div': {
          width: 'calc(100% - 40px)',
        },
      },
    },
    contactWrapper: {
      width: '100%',
    },
    container: {
      background: '#FFF',
      padding: 20,
      boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& h2': {
        margin: 0,
      },
    },
    cardDates: {
      marginBottom: 30,
      display: 'flex',
      gap: 20,
      fontSize: '0.9em'
    },
    highlighted: {
      color: 'var(--primary-color)',
      fontWeight: 'bold'
    },
    reference: {
      color: 'var(--light-grey)',
    },
    detailContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px 40px',
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
    },
    detailLabel: {
      color: '#9B9B9B',
      marginBottom: 10,
    },
    destinationWrapper: {
      display: 'flex',
      gap: 20,
      marginTop: 30,
    },
    destinationContainer: {
      display: 'flex',
      maxWidth: 450,
      width: '100%',
      minHeight: 120,
      margin: 'auto',
      '@media screen and (max-width:780px)': {
        display: 'none',
      },
    },
    destinationContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      marginLeft: 20,
    },
    destination: {
      display: 'flex',
      flexDirection: 'column',
      '&:nth-child(1)': {
        marginTop: -5,
      },
      '&:nth-child(2)': {
        marginBottom: -5,
        marginTop: 'auto',
      },
    },
    timeline: {
      display: 'flex',
      flexDirection: 'column',
      '& span:nth-child(1), span:nth-child(3)': {
        display: 'block',
        width: 10,
        height: 10,
        background: 'var(--primary-color)',
        borderRadius: '100%',
        border: '1px solid var(--primary-color)',
      },
      '& span:nth-child(2)': {
        width: 2,
        background: '#e8e9f5',
        height: 'calc(100% - 30px)',
        display: 'block',
        margin: '5px auto',
      },
      '& span:nth-child(3)': {
        background: 'none',
      },
    },
    contactInfosContainer: {
      display: 'flex',
      gap: 20,
      '@media screen and (max-width:1150px)': {
        flexDirection: 'column !important',
      },
    },
    contactInfosTitle: {
      marginTop: 0,
    },
    contactInfos: {
      display: 'flex',
      alignItems: 'center',
      '&>span': {
        marginLeft: 10,
      },
    },
    weproov: {
      color: 'var(--primary-color)',
      fontWeight: 'bold',
      margin: '-10px 0 20px',
      display: ' block'
    },
    penalty: {
      marginTop: 10,
      color: "#f44336"
    },
    referentInfos: {
      fontSize: '0.8em',
      margin: '-20px 0 25px 0',
      display: 'flex',
      gap: 10,
      color: 'var(--light-grey)',
      flexWrap: 'wrap'
    },
    vehicles: {
      fontSize: 14,
      marginBottom: 10
    }
  });
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Object.keys(mission).length && <>
    <div
      className={classes.outerContainer}
      style={{ flexDirection: mission.driver_name ? 'row' : 'column' }}
    >
      <div className={classes.container + ' ' + classes.travelContainer}>
        <div className={classes.titleContainer}>
          <div>
            <h2>{mission.company}</h2>
            <div className={classes.vehicles}>
              <span>Véhicule : {mission.vehicle_brand} {mission.vehicle_model}</span>
            </div>
            {Boolean(penalties) && <div className={classes.penalty}>Une pénalité de {penalties}€ à été appliquée à la mission</div>}
          </div>
          <span className={classes.reference}>{`N°${mission.reference}`}</span>
        </div>
        <div className={classes.cardDates}>
          <span>Date :<span className={classes.highlighted}>{` ${mission.date_type !== 2 ? window._DATA.date_type[mission.date_type].label : ''} ${formatDate({ time: mission.date, display: 'date' })}`}</span></span>
          <span>Disponibilité du véhicule : <span className={classes.highlighted}>{`${formatDate({ time: mission.vehicle_availability, display: 'date' })}`}</span></span>
          {mission.recovery_date && <span>Récupération : <span className={classes.highlighted}>{`${formatDate({ time: mission.recovery_date, display: 'date' })}`}</span></span>}
          {mission.delivery_date && <span>Livraison : <span className={classes.highlighted}>{`${formatDate({ time: mission.delivery_date, display: 'date' })}`}</span></span>}
          {mission.recovery_delivery_date && <span>Retour : <span className={classes.highlighted}>{`${formatDate({ time: mission.recovery_delivery_date, display: 'date' })}`}</span></span>}
        </div>
        {mission.referent_email && <div className={classes.referentInfos}>
          <span>Coordonnées du référent : </span>
          <span>{mission.referent_name} {mission.referent_firstname}</span>
          <span>{mission.referent_phone}</span>
        </div>}
        {mission.weproov_code && <span className={classes.weproov}>{`Code weproov : ${mission.weproov_code}`}</span>}
        <div className={classes.detailContainer}>
          {Object.entries(details).map(([key, value]) => {
            let missionFormated = mission[key];
            if (['distance', 'duration'].includes(key)) {
              missionFormated = formatDistanceTime({ [key]: mission[key] });
            }

            if (key === 'price' && props.role !== 'driver') {
              missionFormated = missionFormated + ' HT';
              delete missionFormated.price_driver;
            }
            if (key === 'price_driver' && props.role !== 'garage') {
              delete missionFormated.price;
            }

            return (
              missionFormated && (
                <div key={key} className={classes.detail}>
                  <span className={classes.detailLabel}>{value}</span>
                  <span>{missionFormated}</span>
                </div>
              )
            );
          })}
        </div>
        <div className={classes.destinationWrapper}>
          <iframe
            title="Saint-bernard trajet"
            width="100%"
            height="250"
            frameBorder="0"
            style={{ border: 0 }}
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyCVvxDgzXs8ToNAA22MWfch20md201_jbQ&origin=${origin_address}&destination=${destination_address}`}
            allowFullScreen
          ></iframe>
          <div className={classes.destinationContainer}>
            <div className={classes.timeline}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={classes.destinationContent}>
              <div className={classes.destination}>
                <span>
                  {mission.origin_address
                    ? `${mission.origin_address}, ${mission.origin_cp} ${mission.origin_city}`
                    : `${mission.origin_city} ${mission.origin_cp}`}
                </span>
              </div>
              <div className={classes.destination}>
                <span>
                  {mission.destination_address
                    ? `${mission.destination_address}, ${mission.destination_cp} ${mission.destination_city}`
                    : `${mission.destination_city} ${mission.destination_cp}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mission.driver_name && (
        <div className={classes.container}>
          <h2 className={classes.contactInfosTitle}>Informations du conducteur</h2>
          <div className={classes.contactInfosContainer} style={{ flexDirection: 'column' }}>
            <div className={classes.contactInfos}>
              <PersonIcon />
              <span>{`${mission.driver_name} ${mission.driver_firstname}`}</span>
            </div>
            <div className={classes.contactInfos}>
              <PhoneIcon />
              <span>{mission.driver_phone}</span>
            </div>
            <div className={classes.contactInfos}>
              <EmailIcon />
              <span>{mission.driver_email}</span>
            </div>
          </div>
        </div>
      )}
    </div>
    <div className={classes.contactContainer}>
      <div className={classes.container + ' ' + classes.contactWrapper}>
        <h2 className={classes.contactInfosTitle}>Informations du contact de départ</h2>
        <div className={classes.contactInfosContainer}>
          <div className={classes.contactInfos}>
            <PersonIcon />
            <span>{`${mission.starting_contact_name} ${mission.starting_contact_firstname}`}</span>
          </div>
          <div className={classes.contactInfos}>
            <PhoneIcon />
            <span>{mission.starting_contact_phone}</span>
          </div>
          <div className={classes.contactInfos}>
            <EmailIcon />
            <span>{mission.starting_contact_email}</span>
          </div>
        </div>
      </div>
      <div className={classes.container + ' ' + classes.contactWrapper}>
        <h2 className={classes.contactInfosTitle}>Informations du contact d'arrivée</h2>
        <div className={classes.contactInfosContainer}>
          <div className={classes.contactInfos}>
            <PersonIcon />
            <span>{`${mission.incoming_contact_name} ${mission.incoming_contact_firstname}`}</span>
          </div>
          <div className={classes.contactInfos}>
            <PhoneIcon />
            <span>{mission.incoming_contact_phone}</span>
          </div>
          <div className={classes.contactInfos}>
            <EmailIcon />
            <span>{mission.incoming_contact_email}</span>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default MissionDetailRecap;