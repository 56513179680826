// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import { useParams } from 'react-router-dom';
import { prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ForgotPassword = () => {

  const { token } = useParams();
  const { notification } = useNotification()
  const navigate = useNavigate();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "password", component: "text", name: "password", label: "Nouveau mot de passe", required: true },
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  // 
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'reset_password' });

    const params = {
      data,
      endpoint: `/users/password/reset/${token}`
    }

    const resetPassword = await Api(params)

    notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })

    if (resetPassword.success) {
      navigate('/');
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      minHeight: '100vh'
    },
    form: {
      maxWidth: 500,
      margin: "auto",
      background: "#FFF",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      marginTop: 0,
      color: 'var(--primary-color)'
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    register: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#000',
      textDecoration: 'none'
    },
    forgotPassword: {
      textDecoration: 'none',
      color: '#000'
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Link to="/inscription" className={classes.register}>Inscription</Link>
    <form className={classes.form} name='reset_password'>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Réinitialiser le mot de passe</h1>
      </div>
      {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
      <div className={classes.btnContainer}>
        <Button variant="contained" onClick={handleChange} autoFocus>Réinitialiser</Button>
      </div>
    </form>
  </div>
};

export default ForgotPassword;