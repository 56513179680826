//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Steps from '../components/Steps/Steps';
import Api from '../contexts/Api';
import { useNotification } from '../components/UseNotification';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Register = (props) => {
  const [userType, setUserType] = useState(0);
  const { notification } = useNotification();
  const [signIn, setSignIn] = useState();
  const [error, setError] = useState(false);

  const handleUserType = (e) => {
    setUserType(parseInt(e));
  };

  const isDriver = userType === 1;
  const isAdmin = props.from_admin;

  let formFields = [
    {
      fields: [
        { type: 'radio', component: 'radio', name: 'user_type', label: "Type d'utilisateur", required: true, class: 'register-radio-button', value: [{ label: 'Convoyeur', value: 1 }, { label: 'Garage', value: 2 },], handleChange: handleUserType },
        { type: 'text', component: 'text', name: 'firstname', label: 'Prénom', size: 'calc(50% - 10px)', required: true, cond: isDriver },
        { type: 'text', component: 'text', name: 'name', label: 'Nom', size: 'calc(50% - 10px)', required: true, cond: isDriver },
        { type: 'text', component: 'text', name: 'phone', label: 'Numéro de téléphone', size: 'calc(50% - 10px)', required: true },
        { type: 'email', component: 'text', name: 'email', label: 'Email', size: 'calc(50% - 10px)', required: true },
        { type: 'password', component: 'text', name: 'password', label: 'Mot de passe', required: true },
        { type: 'text', component: 'text', name: 'siret', label: 'Numéro de siret', required: true },
        { type: 'text', component: 'text', name: 'address', label: 'Adresse', required: true },
        { type: 'text', component: 'text', name: 'cp', label: 'Code postal', size: 'calc(50% - 10px)', required: true },
        { type: 'text', component: 'text', name: 'city', label: 'Ville', size: 'calc(50% - 10px)', required: true },
        { component: 'checkbox', name: 'contract', label: "J'accepte les termes du contrat (<a href='/assets/files/contrat-chauffeur.pdf' download>télécharger le contrat</a>)", cond: isDriver, required: true },
        { component: 'checkbox', name: 'cgv', label: "J'accepte les <a target='blank' href='http://convoyage.saint-bernard-services.com/conditions-generales-de-vente/'>CGV</a>", cond: !isDriver, required: true },
      ],
    },
  ];

  const companyField = {
    type: 'text',
    component: 'text',
    name: 'company',
    label: 'Nom de la société',
    required: true,
  };

  if (!isDriver) {
    formFields[0].fields.splice(1, 0, companyField);
  }

  if (isDriver) {
    formFields.push(
      {
        fields: [
          { type: 'text', component: 'text', name: 'driver_licence_id', label: 'Numéro du permis', size: 'calc(50% - 10px)', required: true },
          { type: 'date', component: 'datepicker', name: 'driver_licence_date', label: "Date d'obtention", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'text', name: 'driver_licence_place', label: "Lieu d'obtention", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'text', name: 'driver_licence_prefecture', label: 'Préfecture ayant délivré le permis', size: 'calc(50% - 10px)', required: true },
        ],
      },
      {
        fields: [
          { type: 'text', component: 'file', name: 'driver_license_file', label: 'Permis de conduire*', size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'assurance_file', label: 'Assurance W Garage', size: 'calc(50% - 10px)' },
          { type: 'text', component: 'file', name: 'car_registration_file', label: "Carte d'identité*", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'civil_liability_file', label: 'Responsabilité civile', size: 'calc(50% - 10px)' },
          { type: 'text', component: 'file', name: 'rib_file', label: 'RIB*', size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'criminal_record_file', label: 'Contrat signé*', size: 'calc(50% - 10px)', required: true },
        ],
      }
    );
  }

  if (userType === 0) {
    formFields = [
      {
        fields: [
          { type: 'radio', component: 'radio', name: 'user_type', label: "Type d'utilisateur", class: 'register-radio-button', required: true, value: [{ label: 'Convoyeur', value: 1 }, { label: 'Garage', value: 2 },], handleChange: handleUserType },
        ],
      },
    ];
  }

  const stepsLabel = isDriver
    ? ['Données personnelles', 'Permis de conduire', 'Documents']
    : ['Données personnelles'];

  const handleChange = async (data) => {
    setSignIn(false);
    //
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const files = [
      'driver_license_file',
      'assurance_file',
      'car_registration_file',
      'civil_liability_file',
      'rib_file',
      'criminal_record_file',
    ];
    const formData = new FormData();

    files.forEach((file) => {
      if (data[file]) {
        formData.append('files[]', data[file], `${file}-${data[file].name}`);
        delete data[file];
      }
    });
    formData.append('fields', JSON.stringify(data));

    const params = {
      data: formData,
      endpoint: '/oauth/signup',
      method: 'POST',
      removeHeader: true,
    };
    const createUser = await Api(params);

    //
    // ─── SHOW SUCCESS NOTIFICATION ON REGISTER ───────────────────────────────────────
    //
    if (isAdmin) {
      notification({
        variant: createUser.success ? 'success' : 'error',
        message: createUser.message,
      });
      setSignIn(true);
    }

    //
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (createUser.success && createUser.access_token && !isAdmin) {
      setSignIn(true)
    } else if (!createUser.success) {
      setError(true);
    }
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      minHeight: !isAdmin && '100vh',
    },
    form: {
      margin: 'auto',
      padding: 20,

      '& .register-radio-button': {
        padding: '6px 16px',
        boxSizing: 'border-box',
        margin: '10px 10px 10px 0',
        textTransform: 'uppercase',
        border: '1px solid var(--primary-color)',
        background: 'none',
        color: 'var(--primary-color)',
        boxShadow: 'unset',

        '&:has( > .Mui-checked) ': {
          background: 'var(--primary-color)',
          color: '#fff',
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
        '& > span': {
          padding: '0',
          fontSize: '14px',
        },
        '& > span > span': {
          display: 'none'
        },
        '& > span > input': {
          display: 'none'
        },
      }
    },
    signin: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#000',
      textDecoration: 'none',
    },
    loader: {
      width: '70px !important',
      height: '70px !important',
      margin: 'auto',
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
    },
    errorImage: {
      marginTop: 40,
      width: 340,
    },
    errorBtn: {
      width: 300,
      marginTop: '40px !important',
    },
    radioButton: {
      color: 'red'
    }
  });
  const classes = useStyle();

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <div className={classes.container}>
      {!signIn && signIn !== false && (
        <form name="myForm" className={classes.form}>
          <Steps
            formFields={formFields}
            stepsLabel={stepsLabel}
            title={`Inscription ${userType === 1 ? 'chauffeur' : userType === 2 ? 'garage' : ''}`}
            handleChange={handleChange}
            userType={userType}
          />
        </form>
      )}
      {signIn === false && !error && <CircularProgress className={classes.loader} />}
      {!isAdmin && (
        <Link className={classes.signin} to="/connexion">
          Connexion
        </Link>
      )}
      {signIn && !error && isAdmin && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/step_final.svg" alt="Étape finale" className={classes.errorImage} />
          <Button
            variant="contained"
            className={classes.errorBtn}
            onClick={() => window.location.reload()}
          >
            Recharger
          </Button>
        </div>
      )}
      {signIn && !isAdmin && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/registred.svg" alt="Étape finale" className={classes.errorImage} style={{ marginBottom: 40 }} />
          <p>Votre compte a été créé, un administrateur le validera dans les plus brefs délais</p>
        </div>
      )}
      {error && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/error.svg" alt="Erreur" className={classes.errorImage} />
          <Button
            variant="contained"
            className={classes.errorBtn}
            onClick={() => window.location.reload()}
          >
            Erreur veuillez recharger
          </Button>
        </div>
      )}
    </div>
  );
};

export default Register;
