import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Api from "../../contexts/Api";
import { formatDate } from "../../contexts/Utils";

const MissionDetailViews = (props) => {
  const classes = useStyle();
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const getUsersViews = await Api({ endpoint: `/missions/views?mission_id=${props.mission_id}`, method: 'GET', });
      setUsers(getUsersViews.data);
    }

    fetchData();
  }, [props.mission_id])

  return Boolean(users.length) && <div className={classes.container}>
    <h2>Chauffeurs ayant vu l'annonce</h2>
    <div className={classes.users}>
      {users.map((user) => {
        return <div className={classes.userLine}>
          <span>{user.name}</span>
          <span>{user.firstname}</span>
          <span>{user.email}</span>
          <span>{user.phone}</span>
          <span>{formatDate({ time: user.time_create })}</span>
        </div>
      })}
    </div>
  </div>
}

export default MissionDetailViews

//
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const useStyle = createUseStyles({
  container: {
    background: '#FFF',
    padding: '20px 0 0 20px',
    boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
    marginTop: 20
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxHeight: 205,
    overflowY: 'auto',
    padding: '0 20px 20px 0'
  },
  userLine: {
    display: 'flex',
    gap: 40
  }
});