export const MissionEntities = {
  price: { type: 'number', suffix: '€' },
  price_driver: { type: 'number', suffix: '€' },
  cleaning: { type: 'boolean' },
  insurance: { type: 'boolean' },
  electric: { type: 'boolean' },
  easy_to_use: { type: 'boolean' },
  detail: { type: 'text' },
  refueling: { type: 'boolean' },
};
