// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import GeoSearch from './GeoSearch';
import Formfields from './Formfields';
import { prepareFormData } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionSearch = (props) => {

  const [searchParams] = useSearchParams();

  // 
  // ─── LAUNCH SEARCH REQUEST ───────────────────────────────────────
  //
  const handleSearch = () => {
    const data = prepareFormData({ formId: 'search' });
    let search = [];
    Object.entries(data.fields).map(([key, value]) => {
      if (value) {
        search.push(`${key}=${value}`)
      }
    })
    window.location.href = `/?${search.join('&')}`
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5,
      '&>div': {
        flex: 1,
        background: '#FFF',
        width: '50% !important',
        minWidth: 180
      },
    },
    searchBtn: {
      boxShadow: 'none !important'
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "number", component: "text", name: "distance", label: "", placeholder: 'Distance max (km)' },
    { type: "number", component: "text", name: "price", label: "", placeholder: 'Prix max (€)' },
    { type: "text", component: "text", name: "reference", label: "", placeholder: 'Référence' },
  ]

  // 
  // ─── FORMAT GEOSEARCH DEFAULT VALUE ───────────────────────────────────────
  //
  const defaultAddress = searchParams.get('address');
  const defaultCp = searchParams.get('cp');
  const defaultCity = searchParams.get('city');

  let geoDefaults = {};
  if (defaultCity && defaultCp) {
    geoDefaults = {
      geo: !defaultAddress ? `${defaultCity} ${defaultCp}` : `${defaultAddress} ${defaultCp} ${defaultCity}`,
      address: defaultAddress,
      cp: defaultCp,
      city: defaultCity
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <form className={classes.form + ' ' + props.className} name='search'>
    <GeoSearch placeholder="Lieu de recherche" className={classes.geo} default={geoDefaults} />
    {formFields.map((el) => {
      return <Formfields key={el.name} field={{ ...el, value: searchParams.get(el.name) }} className={classes.input} />
    })}
    <Button variant="contained" onClick={handleSearch} className={classes.searchBtn}>Rechercher</Button>
  </form>
};

export default MissionSearch;