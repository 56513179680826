const Api = (opt) => {
  // 
  // ─── CREATION DU HEADER NECESSAIRE A LA REQUETE ───────────────────────────────────────
  //
  let myHeaders = new Headers();
  if (!opt.removeHeader) {
    myHeaders.append("Content-Type", "application/json");
  }


  const auth = localStorage.getItem('saint_bernard_auth')
  // 
  // ─── AJOUT DU TOKEN DANS LE HEADER S'IL EST RENSEIGNE ───────────────────────────────────────
  //
  if (auth) {
    myHeaders.append('Authorization', `Bearer ${JSON.parse(auth).token || ''}`);
  }

  // 
  // ─── DEFINITION DE LA METHODE A UTILISER ───────────────────────────────────────
  //
  let method = opt.method || 'POST'

  // 
  // ─── AJOUT DES OPTIONS DANS LE HEADER ───────────────────────────────────────
  //
  let requestOptions = {
    method,
    headers: myHeaders,
    redirect: 'follow'
  }

  // 
  // ─── AJOUT DES DONNEES SOUS FORME DE JSON STRINGIFY ───────────────────────────────────────
  //
  if (['POST', 'PUT', 'DELETE'].includes(method)) {

    const data = opt.data

    if (auth) {
      data['user_id'] = JSON.parse(auth).user_id
    }

    requestOptions = { ...requestOptions, body: opt.removeHeader ? data : JSON.stringify(data) }
  }

  // 
  // ─── API CONFIGURATIONS ───────────────────────────────────────
  //
  const domain = window.location.hostname;
  let configs = {}

  switch (domain) {
    case "plateforme-convoyage.saint-bernard-services.com":
      configs = {
        path: 'api',
        url: 'plateforme-convoyage.saint-bernard-services.com',
      }
      break;
    default:
      configs = {
        path: process.env.REACT_APP_API_URL,
        url: 'api.olwedev.ovh',
      }
      break;
  }

  // 
  // ─── REQUETE A L'API ───────────────────────────────────────
  //
  return fetch(`https://${configs.url}/${configs.path}${opt.endpoint}`, requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.log('error', error));
}

export default Api;