//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import PersonAdd from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import './Navigation.scss';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Navigation = (props) => {
  const userRole = props.user.role;
  const userDisabled = props.user.disabled;
  const userStatus = props.user.status;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    activeStyle: {
      textDecoration: 'underline !important',
      color: 'var(--primary-color) !important',
    },

  })
  const classes = useStyle();

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <div className="navigation-wrapper">
      <NavLink to="/" className="logo-saintbernard">
        <img src="/assets/img/logo.png" alt="Logo saint bernard" />
      </NavLink>
      <nav className={`navigation ${openMenu ? 'navigation-open' : ''}`}>
        <ul>
          {/* <li>
            <NavLink
              to="/"
              end
              onClick={() => openMenu && setOpenMenu(false)}
              className={({ isActive }) => (isActive ? classes.activeStyle : '')}
            >
              Accueil
            </NavLink>
          </li> */}
          <li>
            {!userDisabled && Boolean(userStatus) && <NavLink to="/" end onClick={() => openMenu && setOpenMenu(false)} className={({ isActive }) => (isActive ? classes.activeStyle : '')}>
              {['garage'].includes(userRole) ? 'Mes convoyages' : 'Toutes les missions'}
            </NavLink>}
          </li>
          {['super-admin', 'garage'].includes(userRole) && !userDisabled && <li style={{ cursor: 'pointer' }} onClick={() => { document.querySelector('.estimation_modal').classList.add('estimation_modal--open') }}>Estimation</li>}
          {['super-admin', 'garage'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
            <li>
              <NavLink to="/nouvelle-mission" onClick={() => openMenu && setOpenMenu(false)} className={({ isActive }) => (isActive ? classes.activeStyle : '')}> Nouvelle mission</NavLink>
            </li>
          )}
          {['driver'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
            <li>
              <NavLink to="/mes-missions" onClick={() => openMenu && setOpenMenu(false)} className={({ isActive }) => (isActive ? classes.activeStyle : '')}>Mes missions</NavLink>
            </li>
          )}
          {['super-admin', 'garage'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
            <li>
              <NavLink to="/mission-en-attente" onClick={() => openMenu && setOpenMenu(false)} className={({ isActive }) => (isActive ? classes.activeStyle : '')}>Convoyage en attente</NavLink>
            </li>
          )}
          {Boolean(userStatus) && <li>
            <NavLink to="/historique" onClick={() => openMenu && setOpenMenu(false)} className={({ isActive }) => (isActive ? classes.activeStyle : '')}>Historique</NavLink>
          </li>}
          {['super-admin'].includes(userRole) && (
            <>
              <li>
                <NavLink
                  to="/penalites"
                  onClick={() => openMenu && setOpenMenu(false)}
                  className={({ isActive }) => (isActive ? classes.activeStyle : '')}
                >
                  Pénalités
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/utilisateurs-en-attente"
                  onClick={() => openMenu && setOpenMenu(false)}
                  className={({ isActive }) => (isActive ? classes.activeStyle : '')}
                >
                  Utilisateurs en attente
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      <Tooltip title="Paramètres du compte" className="avatar-container">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className="avatar-btn"
        >
          <Avatar sx={{ width: 40, height: 40 }}>{`${props.user.user_type === 1
            ? `${props.user.firstname[0]}${props.user.name[0]}`
            : `${props.user.company[0]}`
            }`}</Avatar>
        </IconButton>
      </Tooltip>
      {/* </Box> */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className="navigation__popup"
      >
        <NavLink to="/mon-compte" className="navigation__popup__link navigation__popup__link--main">
          <Avatar /> <span>Mon compte</span>
        </NavLink>
        <Divider className="navigation__popup__divider" />
        {userRole === 'super-admin' && (
          <>
            <NavLink to="/utilisateurs" className="navigation__popup__link navigation__popup__link--secondary">
              <ListItemIcon>
                <GroupIcon fontSize="small" />
              </ListItemIcon>
              <span>Utilisateurs</span>
            </NavLink>
            <NavLink to="/ajouter-un-compte" className="navigation__popup__link navigation__popup__link--secondary">
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <span>Ajouter un compte</span>
            </NavLink>
          </>
        )}
        <MenuItem
          onClick={() => {
            localStorage.removeItem('saint_bernard_auth');
            window.location.href = '/';
          }}
          className="navigation__popup__link navigation__popup__link--secondary"
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <span>Déconnexion</span>
        </MenuItem>
      </Menu>
      <div className={`burger ${openMenu ? 'clicked' : ''}`} onClick={() => setOpenMenu(!openMenu)}>
        <span></span>
      </div>
    </div >
  );
};

export default Navigation;
