//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSearchParams, useLocation } from 'react-router-dom';
import Api from '../contexts/Api';
import Mission from '../components/Mission';
import MissionSearch from '../components/MissionSearch';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import GarageIcon from '@mui/icons-material/Garage';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { useInView } from 'react-intersection-observer';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionsList = (props) => {
  const [offset, setOffset] = useState(0)
  const [missions, setMissions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState();
  const [status, setStatus] = useState();
  const [searchParams] = useSearchParams();
  let search = '';
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const isUserMissions = props.user_missions;
  const isValidateMissions = props.to_validate;
  const isHistoryMissions = props.history;
  const location = useLocation();
  const { ref, inView } = useInView();

  //
  // ─── FORMAT SEARCH PARAMS ───────────────────────────────────────
  //
  for (const entry of searchParams.entries()) {
    search += '&' + entry.join('=');
  }

  const { pathname } = useLocation();
  useEffect(() => {
    setMissions(null)
  }, [pathname])

  //
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  // useEffect(() => {
  const fetchMissions = async (offsetOverride) => {
    const getMissions = await Api({
      endpoint: `/missions?user_id=${user_id}${search}${sort ? '&sort=' + sort : ''}${isUserMissions ? `&driver_id=${user_id}` : ''}${isValidateMissions ? `&company_id=${props.role === 'super-admin' ? 'all' : user_id}&status=${status || '2,3,4'}` : ''}${isHistoryMissions ? `&status=5,6,7&${props.role === 'driver' ? 'driver_id' : 'company_id'}=${props.role === 'super-admin' ? 'all' : user_id}&history=true` : ''}${Boolean(props.search) && ['garage'].includes(props.role) ? `&company_id=${user_id}` : ''}&offset=${offsetOverride ? 0 : offset + 40}${props.role === 'super-admin' ? '&admin=1' : ''}`,
      method: 'GET',
    });
    setLoaded(true);
    if (getMissions.success && getMissions.data.length) {
      if (offsetOverride) {
        setMissions(getMissions.data)
      } else {
        setMissions([...missions, ...getMissions.data]);
        setOffset(offset + 40)
      }
    } else {
      setMissions([]);
    }
  };
  // }, [search, sort, user_id, isUserMissions, isValidateMissions, isHistoryMissions, props.role, status, props.search]);

  useEffect(() => {
    fetchMissions(true);
  }, [location.pathname])

  useEffect(() => {

    if (inView && (missions?.length || 0) >= 20) {
      fetchMissions();
    }
  }, [inView])

  //
  // ─── HANDLE DIALOG STATE ───────────────────────────────────────
  //
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      margin: '0 auto',
      maxWidth: 900,
      padding: 20,
    },
    search: {
      marginBottom: 60,
    },
    container: {
      display: 'flex',
      gap: 20,
      '@media screen and (max-width:780px)': {
        flexDirection: 'column',
      },
    },
    detail: {
      width: '100%',
      minHeight: 'calc(100vh - 74px)',
    },
    missions: {
      width: '100%',
    },
    mission: {
      marginBottom: 20,
    },
    filters: {
      '@media screen and (max-width:780px)': {
        display: 'none',
      },
    },
    mobileFilters: {
      display: 'none',
      '@media screen and (max-width:780px)': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    filterList: {
      '@media screen and (max-width:780px)': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    filterDialog: {
      maxWidth: 360,
      margin: 'auto',
    },
    filterDialogClose: {
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
    },
    filterTitle: {
      paddingLeft: 16,
      fontWeight: 'bold',
      display: 'block',
      marginBottom: 10,
      fontSize: 18,
    },
    noResult: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 'auto',
      '& img': {
        width: 400,
      },
      '& span': {
        textAlign: 'center',
        marginTop: 10,
        fontSize: '1.1em',
      },
    },
  });
  const classes = useStyle();

  //
  // ─── SET MISSION LIST SORT ───────────────────────────────────────
  //
  const orderMissions = (sort_type) => {
    setSort(sort_type);
  };

  const filtersContainer = (
    <>
      <span className={classes.filterTitle}>Trier par</span>
      <MenuList className={classes.filterList}>
        <MenuItem onClick={() => orderMissions('price%asc')}>
          <ListItemIcon>
            <LocalAtmIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Prix le plus bas</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => orderMissions('date%asc')}>
          <ListItemIcon>
            <AccessTimeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Départ le plut tôt</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => orderMissions('distance%asc')}>
          <ListItemIcon>
            <HourglassEmptyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Trajet le plus court</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => orderMissions('company%asc')}>
          <ListItemIcon>
            <GarageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Garage</ListItemText>
        </MenuItem>
        {['super-admin', 'garage'].includes(props.role) && (
          <div>
            <Divider />
            <MenuItem onClick={() => setStatus(null)}>
              <ListItemIcon>
                <RestartAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Réinitialiser la recherche</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setStatus(2)}>
              <ListItemIcon>
                <DirectionsCarIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Validation convoyeur</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setStatus(3)}>
              <ListItemIcon>
                <SupervisorAccountIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Validation admin</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setStatus(4)}>
              <ListItemIcon>
                <PlayCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>En cours</ListItemText>
            </MenuItem>
          </div>
        )}
      </MenuList>
    </>
  );

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <div className={classes.wrapper}>
      {loaded ? (
        <>
          {!isUserMissions && !isHistoryMissions && !isValidateMissions && (
            <MissionSearch className={classes.search} />
          )}
          <div className={classes.container}>
            <div className={classes.filters}>{filtersContainer}</div>
            <div className={classes.mobileFilters}>
              <IconButton onClick={handleClickOpen}>
                <FilterListIcon />
              </IconButton>
              <span>Filtres</span>
            </div>
            {missions && Boolean(missions.length) ? (
              <div className={classes.missions}>
                <div>
                  {(missions || []).map((mission) => {
                    return <div ref={ref}>
                      <Mission

                        key={mission.mission_id}
                        data={mission}
                        className={classes.mission}
                        is_search={!props.search}
                        role={props.role}
                      />
                    </div>
                  })}
                </div>
              </div>
            ) : missions && Array.isArray(missions) && (
              <div className={classes.noResult}>
                <img alt="Aucun résultat" src="/assets/img/no_result.svg" />
                <span>Aucun résultat</span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>Chargement</div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.filterDialog}
      >
        <DialogContent>
          <CloseIcon className={classes.filterDialogClose} onClick={handleClose} />
          {filtersContainer}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MissionsList;
