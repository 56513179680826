// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../slice/auth';
import Api from '../contexts/Api';
import { prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Login = () => {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { notification } = useNotification()

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", label: "Email" },
    { type: "password", component: "text", name: "password", label: "Mot de passe" },
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'loginForm' });

    const params = {
      data,
      endpoint: '/oauth/login'
    }

    const logUser = await Api(params)

    // 
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (logUser.success && logUser.access_token) {
      dispatch(login({ access_token: logUser.access_token, user_id: logUser.user_id }))
      return navigate("/");
    } else {
      notification({ variant: 'error', message: logUser.message })
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      minHeight: '100vh',
      padding: '0 20px'
    },
    logo: {
      '@media screen and (max-width:450px)': {
        width: 150
      }
    },
    form: {
      maxWidth: 500,
      margin: "auto",
      background: "#FFF",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      marginTop: 0,
      color: 'var(--primary-color)',
      '@media screen and (max-width:450px)': {
        fontSize: 20
      }
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    register: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#000',
      textDecoration: 'none'
    },
    forgotPassword: {
      textDecoration: 'none',
      color: '#000'
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Link to="/inscription" className={classes.register}>Inscription</Link>
    <form className={classes.form} name='loginForm'>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Connexion</h1>
        <img src='/assets/img/logo.png' className={classes.logo} alt='Logo Saint-Bernard' />
      </div>
      {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
      <div className={classes.btnContainer}>
        <Link to="/mot-de-passe-oublie" className={classes.forgotPassword}>Mot de passe oublié</Link>
        <Button variant="contained" onClick={handleChange} autoFocus>Connexion</Button>
      </div>
    </form>
  </div>
};

export default Login;