//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { formatDistanceTime, formatDate, formatData, getValue } from '../contexts/Utils';
import { Euro } from '@mui/icons-material';
import { PenaltiesList } from '../contexts/Contexts'
import Api from '../contexts/Api'
import { useNavigate } from 'react-router-dom';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Mission = (props) => {
  let data = props.data;
  const navigate = useNavigate();

  const formatedData = formatData({ data });

  const status = {
    1: { color: '#66bb6a' },
    2: { color: '#ffa726' },
    3: { color: '#ffa726' },
    4: { color: '#29b6f6' },
    5: { color: '#f44336' },
    6: { color: '#66bb6a' },
    7: { color: '#f44336' },
  };

  let penalties = 0;

  PenaltiesList.map((penalty) => {
    if (data[penalty.name]) {
      penalties += data[penalty.name];
    }
  })

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    link: {
      textDecoration: 'none',
      display: 'block',
    },
    card: {
      background: '#FFF',
      boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
      padding: 20,
      color: 'var(--text-color)',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& h3': {
        margin: 0,
      },
    },
    cardDates: {
      marginBottom: 30,
      display: 'flex',
      gap: 20,
      fontSize: '0.9em'
    },
    highlighted: {
      color: 'var(--primary-color)',
      fontWeight: 'bold'
    },
    referenceContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    reference: {
      color: 'var(--light-grey)',
    },
    destinationWrapper: {
      display: 'flex',
    },
    destinationContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      marginLeft: 20,
    },
    destination: {
      display: 'flex',
      flexDirection: 'column',
      '&:nth-child(1)': {
        marginTop: -5,
        marginBottom: 20,
      },
      '&:nth-child(2)': {
        marginBottom: -5,
      },
    },
    timeline: {
      display: 'flex',
      flexDirection: 'column',
      '& span:nth-child(1), span:nth-child(3)': {
        display: 'block',
        width: 10,
        height: 10,
        background: 'var(--primary-color)',
        borderRadius: '100%',
        border: '1px solid var(--primary-color)',
      },
      '& span:nth-child(2)': {
        width: 2,
        background: '#e8e9f5',
        height: 'calc(100% - 30px)',
        display: 'block',
        margin: '5px auto',
      },
      '& span:nth-child(3)': {
        background: 'none',
      },
    },
    divider: {
      width: '90%',
      height: 1,
      background: '#EBECF6',
      margin: '45px auto 20px',
    },
    traveilDetailContainer: {
      display: 'flex',
      gap: 20,
      width: '90%',
      margin: 'auto',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    travelDetail: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& svg': {
        fill: '#8F8C98',
        fontSize: '22px',
      },
    },
    status: {
      marginTop: 20,
      color: status[data.status].color,
      display: 'block',
    },
    penalty: {
      marginTop: 10,
      color: "#f44336"
    },
    referentInfos: {
      fontSize: '0.8em',
      margin: '20px 0 -30px 0',
      display: 'flex',
      gap: 10,
      color: 'var(--light-grey)',
      flexWrap: 'wrap'
    },
    vehicles: {
      fontSize: 14,
      marginBottom: 10
    },
    recovery: {
      fontSize: 13,
      marginLeft: 'auto'
    },
    proposals: {
      color: 'var(--primary-color)',
      fontWeight: 'bold'
    },
    duplicate: {
      background: 'var(--primary-color)',
      width: 'fit-content',
      margin: '20px 0 0 auto',
      padding: '5px 10px',
      color: '#FFF',
      '&:hover': {
        background: '#75af90'
      }
    }
  });
  const classes = useStyle();

  let vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M640 320V368C640 385.7 625.7 400 608 400H574.7C567.1 445.4 527.6 480 480 480C432.4 480 392.9 445.4 385.3 400H254.7C247.1 445.4 207.6 480 160 480C112.4 480 72.94 445.4 65.33 400H32C14.33 400 0 385.7 0 368V256C0 224.5 22.78 198.3 52.78 192.1L97.05 82.29C109.2 51.92 138.6 32 171.3 32H353.2C377.5 32 400.5 43.05 415.7 62.02L519.9 192.2C586.9 196.3 640 251.1 640 320H640zM171.3 64C151.7 64 134.1 75.95 126.8 94.17L87.63 192H224V64H171.3zM256 192H478.7L390.7 82.01C381.6 70.63 367.8 64 353.2 64H256L256 192zM541.1 400C543.3 394.9 544 389.5 544 384C544 378.5 543.3 373.1 541.1 368C534.9 340.4 509.8 320 480 320C450.2 320 425.1 340.4 418 368C416.7 373.1 416 378.5 416 384C416 389.5 416.7 394.9 418 400C425.1 427.6 450.2 448 480 448C509.8 448 534.9 427.6 541.1 400zM385.3 368C392.9 322.6 432.4 288 480 288C527.6 288 567.1 322.6 574.7 368H608V320C608 266.1 565 224 512 224H64C46.33 224 32 238.3 32 256V368H65.33C72.94 322.6 112.4 288 160 288C207.6 288 247.1 322.6 254.7 368H385.3zM221.1 400C223.3 394.9 224 389.5 224 384C224 378.5 223.3 373.1 221.1 368C214.9 340.4 189.8 320 160 320C130.2 320 105.1 340.4 98.02 368C96.7 373.1 96 378.5 96 384C96 389.5 96.7 394.9 98.02 400C105.1 427.6 130.2 448 160 448C189.8 448 214.9 427.6 221.1 400z" /></svg>

  if (data.vehicle_type === 2) {
    vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H481.3C495.3 96 508.6 102.1 517.7 112.8L596.4 204.6C603.9 213.3 608 224.4 608 235.8V384H624C632.8 384 640 391.2 640 400C640 408.8 632.8 416 624 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H255.1C255.1 469 213 512 159.1 512C106.1 512 63.1 469 63.1 416H64zM32 64V352C32 369.7 46.33 384 64 384H69.46C82.64 346.7 118.2 320 160 320C201.8 320 237.4 346.7 250.5 384H384V64C384 46.33 369.7 32 352 32H64C46.33 32 32 46.33 32 64zM570.9 224L493.4 133.6C490.4 130 485.1 128 481.3 128H416V224H570.9zM416 256V344.4C432.1 329.2 455.4 320 480 320C521.8 320 557.4 346.7 570.5 384H576V256H416zM160 352C124.7 352 96 380.7 96 416C96 451.3 124.7 480 160 480C195.3 480 224 451.3 224 416C224 380.7 195.3 352 160 352zM480 480C515.3 480 544 451.3 544 416C544 380.7 515.3 352 480 352C444.7 352 416 380.7 416 416C416 451.3 444.7 480 480 480z" /></svg>
  } else if (data.vehicle_type === 3) {
    vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M640 250.7V360C640 379.4 626.3 395.5 607.1 399.2L608 400C608 444.2 572.2 480 528 480C483.8 480 448 444.2 448 400H288C288 444.2 252.2 480 208 480C181.8 480 158.6 467.4 144 448C129.4 467.4 106.2 480 80 480C35.82 480 0 444.2 0 400V104C0 64.24 32.24 32 72 32H408C447.8 32 480 64.24 480 104V128H536C551.1 128 565.3 135.1 574.4 147.2L630.4 221.9C636.6 230.2 640 240.3 640 250.7zM32 104V335.1C45.37 325.1 61.99 320 80 320C106.2 320 129.4 332.6 144 351.1C158.6 332.6 181.8 320 208 320C240.8 320 268.1 339.7 281.3 368H448V104C448 81.91 430.1 64 408 64H72C49.91 64 32 81.91 32 104zM528 320C560.8 320 588.9 339.7 601.3 367.9C605.1 367.3 608 363.1 608 360V256H480V335.1C493.4 325.1 509.1 320 528 320zM480 160V224H592L548.8 166.4C545.8 162.4 541 160 536 160H480zM528 448C554.5 448 576 426.5 576 400C576 373.5 554.5 352 528 352C501.5 352 480 373.5 480 400C480 426.5 501.5 448 528 448zM208 352C181.5 352 160 373.5 160 400C160 426.5 181.5 448 208 448C234.5 448 256 426.5 256 400C256 373.5 234.5 352 208 352zM80 448C106.5 448 128 426.5 128 400C128 373.5 106.5 352 80 352C53.49 352 32 373.5 32 400C32 426.5 53.49 448 80 448z" /></svg>
  }

  const handleReplicate = async (e, mission_id) => {
    e.preventDefault();
    const diplicateMission = await Api({ endpoint: '/missions/replicate', data: { mission_id } });

    if (diplicateMission.success) {
      navigate(`/editer/${diplicateMission.data.mission_id}`);
    }
  }

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <a href={`${window.location.pathname !== '/' ? window.location.pathname : '/mission'}/${data.uuid}`} className={classes.link + ' ' + props.className}>
      <div className={classes.card}>
        <div className={classes.titleContainer}>
          <div>
            <h3>{data.company}</h3>
            <div className={classes.vehicles}>
              <span>Véhicule : {data.vehicle_brand} {data.vehicle_model}</span>
            </div>
          </div>
          <div className={classes.referenceContainer}>
            <span className={classes.reference}>{`N°${data.reference}`}</span>
            {Boolean(data.recovery) && <span className={classes.recovery}>Aller / Retour</span>}
          </div>

        </div>
        <div className={classes.cardDates}>
          <span>Date :<span className={classes.highlighted}>{` ${data.date_type !== 2 ? window._DATA.date_type[data.date_type].label : ''} ${formatDate({ time: data.date, display: 'date' })}`}</span></span>
          <span>Disponibilité du véhicule : <span className={classes.highlighted}>{`${formatDate({ time: data.vehicle_availability, display: 'date' })}`}</span></span>
          {data.recovery_date && <span>Récupération : <span className={classes.highlighted}>{`${formatDate({ time: data.recovery_date, display: 'date' })}`}</span></span>}
          {data.delivery_date && <span>Livraison : <span className={classes.highlighted}>{`${formatDate({ time: data.delivery_date, display: 'date' })}`}</span></span>}
          {data.recovery_delivery_date && <span>Retour : <span className={classes.highlighted}>{`${formatDate({ time: data.recovery_delivery_date, display: 'date' })}`}</span></span>}
        </div>
        <div className={classes.destinationWrapper}>
          <div className={classes.timeline}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={classes.destinationContainer}>
            <div className={classes.destination}>
              <span>
                {data.origin_address
                  ? `${data.origin_address}, ${data.origin_cp} ${data.origin_city}`
                  : `${data.origin_city} ${data.origin_cp}`}
              </span>
            </div>
            <div className={classes.destination}>
              <span>
                {data.destination_address
                  ? `${data.destination_address}, ${data.destination_cp} ${data.destination_city}`
                  : `${data.destination_city} ${data.destination_cp}`}
              </span>
            </div>
          </div>
        </div>
        {data.referent_email && <div className={classes.referentInfos}>
          <span>Coordonnées du référent : </span>
          <span>{data.referent_name} {data.referent_firstname}</span>
          <span>{data.referent_phone}</span>
        </div>}
        <div className={classes.divider} />
        <div className={classes.traveilDetailContainer}>
          <div className={classes.travelDetail}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
              <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"></path>
              <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M13 12H18"></path>
              <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 5V11"></path>
              <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"></path>
            </svg>
            <span>{formatDistanceTime({ duration: data.duration })}</span>
          </div>
          <div className={classes.travelDetail}>
            {vehicleIcon}
            <span>{formatDistanceTime({ distance: data.distance })}</span>
          </div>
          {props.role !== 'driver' && <div className={classes.travelDetail}>
            <Euro />
            <span>{formatedData.price} HT</span>
          </div>}
          {props.role !== 'garage' && formatedData.price_driver && <div className={classes.travelDetail}>
            <Euro />
            <span>{formatedData.price_driver}</span>
          </div>}
        </div>
        {props.is_search && <span className={classes.status}>{getValue(window, ['_DATA', 'mission_status', data.status, 'label'])}</span>}
        {Boolean(data.driver_count) && props.role === 'super-admin' && data.status <= 2 && <div className={classes.proposals} style={{ marginTop: 20 }}>Nombre de propositions : {data.driver_count} chauffeur{data.driver_count > 1 ? 's' : ''}</div>}
        {Boolean(data.user_count) && props.role === 'super-admin' && data.status <= 5 && <div className={classes.proposals} style={{ marginTop: Boolean(data.driver_count) && props.role === 'super-admin' ? 0 : 20 }}>Nombre de vues : {data.user_count}</div>}
        {Boolean(penalties) && <div className={classes.penalty}>Une pénalité de {penalties}€ à été appliquée à la mission</div>}
        {props.role === 'super-admin' && <div className={classes.duplicate} onClick={(e) => handleReplicate(e, data.uuid)}>Dupliquer</div>}
      </div>
    </a>
  );
};

export default Mission;
