// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Api from '../contexts/Api';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UserList = () => {
  const storedData = JSON.parse(localStorage.getItem('saint_bernard_auth'));
  const [users, setUsers] = useState([])

  //
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUsers = async () => {
      const getUsers = await Api({
        endpoint: `/users?user_id=${storedData.user_id}&search_all=1&status=0`,
        method: 'GET',
      });
      if (getUsers.data) {
        setUsers(getUsers.data)
      }
    };
    if (!users.length) {
      fetchUsers();
    }
  }, [storedData.user_id, users]);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      maxWidth: 1200,
      margin: 'auto',
      padding: 20
    },
    container: {
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      padding: 20,
      background: '#FFF',
      marginBottom: 10,
      display: 'flex',
      gap: 20,
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    link: {
      textDecoration: 'none',
      color: 'var(--primary-color)',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  })
  const classes = useStyle();

  const handleChange = async (user_id) => {
    const params = {
      endpoint: `/users`,
      method: 'PUT',
      data: {
        user_id: storedData.user_id,
        company_id: user_id,
        validate_user: true,
        fields: {
          status: 1,
        }
      },
    };

    await Api(params);

    setUsers(users.filter((element) => element.user_id !== user_id))
  }

  const handleDeleteUser = async (user_id) => {
    const params = {
      endpoint: `/users`,
      method: 'DELETE',
      data: {
        user_id: storedData.user_id,
        company_id: user_id,
      },
    };

    await Api(params);

    setUsers(users.filter((element) => element.user_id !== user_id))
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <h1>Utilisateurs à valider</h1>
    <div>
      {users.map((user) => {
        return <div className={classes.container}>
          {!user.company && <span>{`${user.name} ${user.firstname}`}</span>}
          {user.company && <span>{user.company}</span>}
          <span>{user.email}</span>
          <span>{user.phone}</span>
          <Link className={classes.link} to={`/utilisateurs/${user.user_id}`} style={{ marginLeft: 'auto' }}>aperçu</Link>
          <Button onClick={() => handleChange(user.user_id)} autoFocus variant="contained" size="small" >Valider</Button>
          <Button onClick={() => handleDeleteUser(user.user_id)} autoFocus variant="contained" color="error" size="small" >Refuser</Button>
        </div>
      })}
    </div>
  </div>
};

export default UserList;