import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Formfields from '../Formfields';
import { prepareFormData, getValue } from '../../contexts/Utils';
import { useLocation } from 'react-router-dom';

const Steps = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const handleNext = () => {
    const data = prepareFormData({ formId: 'myForm' });
    if (data.errors) {
      setErrors(data.errors);
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      setForm({ ...form, ...data.fields });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinish = () => {
    const data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setForm({ ...form, ...data.fields });
      props.handleChange({ ...form, ...data.fields });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      maxWidth: 800,
      margin: 'auto',
    },
    card: {
      background: '#FFF',
      padding: 20,
      boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
      marginTop: 40,
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      width: '100%',
      marginTop: 0,
      color: 'var(--primary-color)',
      '@media screen and (max-width:450px)': {
        fontSize: 20,
      },
    },
    logo: {
      '@media screen and (max-width:450px)': {
        width: 150,
      },
    },
    steps: {
      width: '100%',
      maxWidth: 800,
    },
  });
  const classes = useStyle();

  return (
    <div className={classes.container}>
      {activeStep !== props.stepsLabel.length && props.stepsLabel.length > 1 && (
        <Stepper activeStep={activeStep} className={classes.steps}>
          {props.stepsLabel.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep !== props.stepsLabel.length && (
        <>
          <div className={classes.card}>
            {location.pathname !== '/ajouter-un-compte' && (
              <div className={classes.titleContainer}>
                <h1 className={classes.title}>{props.title}</h1>
                <img src="/assets/img/logo.png" className={classes.logo} alt="Logo Saint-Bernard" />
              </div>
            )}
            {props.formFields[activeStep].fields.map((field) => {
              const defaults = form[field.name] || field.default;
              const checkCond = field.cond || !field.hasOwnProperty('cond');

              return (
                checkCond && (
                  <Formfields
                    key={field.name}
                    field={{
                      ...field,
                      ...{ default: defaults, error: getValue(errors, [field.name, 'message']) },
                    }}
                  />
                )
              );
            })}

            <div className={classes.btnContainer}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
              {props.userType !== 0 && (
                <Button
                  onClick={activeStep === props.stepsLabel.length - 1 ? handleFinish : handleNext}
                >
                  {activeStep === props.stepsLabel.length - 1 ? 'Terminer' : 'Suivant'}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Steps;
