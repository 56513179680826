//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Formfields from './Formfields';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const GeoSearch = (props) => {
  const [geo, setGeo] = useState([]);
  const [search, setSearch] = useState('');
  const [geoApi, setGeoApi] = useState({});
  const [defaultValue, setDefaultValue] = useState(props.default || {});

  // let defaultValue = props.default || {};

  useEffect(() => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();

    const timer = setTimeout(() => {
      if (search) {
        autocompleteService.getPlacePredictions(
          {
            input: search,
            // types: ['address'],
            componentRestrictions: { country: 'fr' }, // limit results to France
          },
          (predictions) => {
            if (predictions) {
              const geoArray = predictions.map((prediction) => ({
                label: prediction.description,
                placeId: prediction.place_id,
              }));
              setGeo([...geoArray]);
            }
          }
        );
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [search]);

  const handleSelect = (value) => {
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

    placesService.getDetails({ placeId: value.placeId }, (placeResult) => {
      if (placeResult) {
        const addressComponents = placeResult.address_components;

        const geoValues = {
          label: placeResult.formatted_address,
          city: getComponentValue(addressComponents, 'locality'),
          cp: getComponentValue(addressComponents, 'postal_code'),
          address: getStreetAddress(addressComponents),
        };

        setGeoApi({ ...geoValues });
        props.handleChange({ [props.name]: geoValues })
      }
    });
  };

  const getComponentValue = (addressComponents, type) => {
    const component = addressComponents.find((component) => component.types.includes(type));
    return component ? component.long_name : '';
  };

  const getStreetAddress = (addressComponents) => {
    const streetNumber = getComponentValue(addressComponents, 'street_number');
    const streetName = getComponentValue(addressComponents, 'route');
    return streetNumber && streetName ? `${streetNumber} ${streetName}` : '';
  };

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    geo && (
      <>
        <Autocomplete
          id={props.name}
          size="small"
          options={geo}
          // getOptionLabel={(option) => option.label}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue);
          }}
          onChange={(e, value) => {
            if (value) {
              handleSelect(value);
            }
          }}
          value={search || defaultValue.geo}
          className={props.className}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              placeholder={props.placeholder}
              name={props.name}
              InputLabelProps={{ shrink: Boolean(props.shrink) }}
            />
          )}
        />

        <Formfields
          field={{
            type: 'hidden',
            component: 'text',
            name: `${props.name ? `${props.name}_` : ''}address`,
            hidden: true,
            value:
              geoApi.address || (geoApi.cp && !geoApi.address)
                ? geoApi.address
                : defaultValue.address,
          }}
        />
        <Formfields
          field={{
            type: 'hidden',
            component: 'text',
            name: `${props.name ? `${props.name}_` : ''}cp`,
            hidden: true,
            value: geoApi.cp || defaultValue.cp,
          }}
        />
        <Formfields
          field={{
            type: 'hidden',
            component: 'text',
            name: `${props.name ? `${props.name}_` : ''}city`,
            hidden: true,
            value: geoApi.city || defaultValue.city,
          }}
        />
      </>
    )
  );
};

export default GeoSearch;
