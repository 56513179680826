//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MissionsList from '../views/MissionsList';
import CreateMission from '../views/CreateMission';
import Account from '../views/Account';
import NotFound from '../views/NotFound';
import MissionDetail from '../views/MissionsDetail';
import Register from '../containers/Register';
import PenaltyList from '../views/PenaltyList';
import UsersList from '../views/UsersList';
import Users from '../views/Users';
import UserDetail from '../views/UserDetail';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = (props) => {

  let userDisabled = props.user.disabled;
  let userStatus = props.user.status;

  const routes = [
    { path: '/', component: <MissionsList role={props.user.role} search={true} />, disabled: userDisabled || !userStatus },
    { path: '/nouvelle-mission', component: <CreateMission role={props.user.role} partner={props.user.partner} partner_custom={props.user.partner_custom} />, role: ['super-admin', 'garage'], disabled: userDisabled || !userStatus },
    { path: '/mission/:uuid', component: <MissionDetail role={props.user.role} search={true} user={props.user} />, disabled: userDisabled || !userStatus },
    { path: '/mes-missions', component: <MissionsList user_missions={true} role={props.user.role} />, role: ['driver'], disabled: userDisabled || !userStatus },
    { path: '/mes-missions/:uuid', component: <MissionDetail user_mission={true} role={props.user.role} />, role: ['driver'], disabled: userDisabled || !userStatus },
    { path: '/editer/:uuid', component: <CreateMission role={props.user.role} partner={props.user.partner} edit={true} partner_custom={props.user.partner_custom} />, role: ['super-admin', 'garage'], disabled: userDisabled || !userStatus },
    { path: '/mission-en-attente', component: <MissionsList user_mission={true} role={props.user.role} to_validate={true} />, role: ['super-admin', 'garage'], disabled: userDisabled || !userStatus },
    { path: '/mission-en-attente/:uuid', component: <MissionDetail role={props.user.role} to_validate={true} />, role: ['super-admin', 'garage'], disabled: userDisabled || !userStatus },
    { path: '/historique', component: <MissionsList history={true} role={props.user.role} />, disabled: !userStatus },
    { path: '/historique/:uuid', component: <MissionDetail history={true} role={props.user.role} />, disabled: !userStatus },
    { path: '/mon-compte', component: <Account role={props.user.role} /> },
    { path: '/utilisateurs', component: <Users role={props.user.role} />, role: ['super-admin'] },
    { path: '/utilisateurs/:user_id', component: <UserDetail role={props.user.role} />, role: ['super-admin'] },
    { path: '/penalites', component: <PenaltyList role={props.user.role} />, role: ['super-admin'] },
    { path: '/utilisateurs-en-attente', component: <UsersList role={props.user.role} />, role: ['super-admin'] },
    { path: '/ajouter-un-compte', component: <Register role={props.user.role} from_admin={true} />, role: ['super-admin'], },
  ];

  //
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return (
    <Routes>
      {routes.map(
        (route) =>
          ((route.role && route.role.includes(props.user.role)) ||
            !route.hasOwnProperty('role')) && !route.disabled && (
            <Route key={route.path} path={route.path} element={route.component} />
          )
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
