// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Api from '../contexts/Api';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { getValue } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Users = () => {
  const storedData = JSON.parse(localStorage.getItem('saint_bernard_auth'));
  const [users, setUsers] = useState({ list: [], filtred: [] })

  //
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUsers = async () => {
      const getUsers = await Api({
        endpoint: `/users?user_id=${storedData.user_id}&search_all=1&remove_admin=true`,
        method: 'GET',
      });
      if (getUsers.data) {
        setUsers({ list: getUsers.data, filtred: [] })
      }
    };
    if (!users.list.length) {
      fetchUsers();
    }
  }, [storedData.user_id, users]);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      maxWidth: 1200,
      margin: 'auto',
      padding: 20
    },
    container: {
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      padding: 20,
      background: '#FFF',
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    type: {
      fontSize: 12,
      padding: '4px 8px'
    },
    content: {
      display: 'flex',
      gap: 20,
    },
    link: {
      textDecoration: 'none',
      color: 'var(--primary-color)',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 10,
      color: 'var(--subtitle-color)'
    },
    filtersBtn: {
      display: 'flex',
      gap: 40,
      cursor: 'pointer',
      '& > div:hover ': {
        color: 'var(--primary-color)',
      }
    },
    filtersSearch: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    filtersSearchInput: {
      background: 'none',
      border: '1px solid #e1e1e1',
      padding: 5,
      width: 240,
      outline: 'none'
    }
  })
  const classes = useStyle();


  // 
  // ─── SEARCH FILTER ON PROJECT LIST ───────────────────────────────────────
  //
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const tmpUsers = users.list;
    const tmpList = tmpUsers.filter(element => {
      if (element.user_type === 1) {
        const fullName = `${element.firstname} ${element.name}`.toLowerCase();
        const reversedFullName = `${element.name} ${element.firstname}`.toLowerCase(); // Ajout de la recherche inversée

        const fullNameNoAccents = removeAccents(fullName);
        const reversedFullNameNoAccents = removeAccents(reversedFullName); // Suppression des accents pour la recherche inversée

        if (fullName.includes(value) || fullNameNoAccents.includes(value) ||
          reversedFullName.includes(value) || reversedFullNameNoAccents.includes(value)) {
          return element;
        }
      } else if (element.user_type === 2) {
        const company = element.company.toLowerCase();
        const companyNoAccents = removeAccents(company);

        if (company.includes(value) || companyNoAccents.includes(value)) {
          return element;
        }
      }

      const email = element.email.toLowerCase();
      const phone = element.phone.toLowerCase();

      if (email.includes(value) || phone.includes(value)) {
        return element;
      }
    });

    setUsers({ ...users, filtred: tmpList });
  }

  // Fonction pour supprimer les accents
  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  let usersDisplay = users.list;

  if (users.filtred.length) {
    usersDisplay = users.filtred
  }


  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <h1>Utilisateurs</h1>
    <div className={classes.filters}>
      <div className={classes.filtersBtn}>
        <div onClick={() => setUsers({ list: users.list, filtred: [] })}>Tous les utilisateurs</div>
        <div onClick={() => setUsers({ list: users.list, filtred: users.list.filter(element => element.user_type === 1) })}>Chauffeurs</div>
        <div onClick={() => setUsers({ list: users.list, filtred: users.list.filter(element => element.user_type === 2) })}>Garages</div>
      </div>
      <div className={classes.filtersSearch}>
        <input type="text" className={classes.filtersSearchInput} onChange={(e) => handleSearch(e)} />
        <SearchIcon />
      </div>
    </div>
    <div>
      {usersDisplay.map((user) => {
        const type = user.user_type;

        return <div className={classes.container}>
          <div className={classes.content}>
            <span style={{ background: type === 1 ? '#afd0ff' : '#fbc857' }} className={classes.type}>{getValue(window, ['_DATA', 'user_type', type, 'label'])}</span>
            {!user.company && <span>{`${user.name} ${user.firstname}`}</span>}
            {user.company && <span>{user.company}</span>}
            <span>{user.email}</span>
            <span>{user.phone}</span>
          </div>
          <Link className={classes.link} to={`/utilisateurs/${user.user_id}`}>aperçu</Link>
        </div>
      })}
    </div>
  </div>
};

export default Users;