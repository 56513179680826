// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Formfields from '../components/Formfields';
import Api from '../contexts/Api';
import { prepareFormData, getValue } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UserDetail = (props) => {

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const { user_id } = useParams();
  const [user, setUser] = useState({})
  const [partner, setPartner] = useState(1)
  const [errors, setErrors] = useState({})
  const { notification } = useNotification();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const personalizedFields = ['De 0 à 49 km', 'De 50 à 99 km', 'De 100 à 149 km', 'De 150 à 219 km', 'De 220 à 399 km', 'De 400 à 449 km', '450 km et plus'];
  const vehicles = ['Véhicule léger', 'Véhicule utilitaire (inférieur à 2m)', 'Véhicule utilitaire (supérieur à 2m)'];
  const canPartner = user.user_type === 2 && Boolean([1, 4].includes(partner)) && props.role === 'super-admin';

  // 
  // ─── GET USER INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: `/users?user_id=${user_id}`,
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setUser({ ...getClients.data[0], partner_custom: JSON.parse(getClients.data[0].partner_custom) })
        setPartner(getClients.data[0].partner)
      }
    }
    if (!Object.keys(user).length) {
      fetchData()
    }
  }, [user, user_id])

  // 
  // ─── HANDLE PERSONAL INFOS UPDATE ───────────────────────────────────────
  //
  const handlePersonalInfos = async (formId) => {
    const data = prepareFormData({ formId: formId });

    if (data.errors) {
      setErrors({ ...errors, ...data.errors })
      return;

    } else {

      if (canPartner) {
        const personalizedData = prepareFormData({ formId: 'personalized-form' });

        if (personalizedData.errors) {
          setErrors({ ...errors, ...personalizedData.errors });
          return;
        }

        // prepare data for personalized json
        const formatData = {};
        vehicles.forEach((cat, i) => {
          if (partner === 1) {
            formatData[i + 1] = {
              promo: parseFloat(personalizedData.fields[`${cat}-promo`]),
            }
          }
          if (partner === 4) {
            formatData[i + 1] = [];

            personalizedFields.forEach(field => {
              formatData[i + 1].push({
                value: parseFloat(personalizedData.fields[`${cat}-${field}`]),
                coeff: personalizedData.fields[`${cat}-${field}-coeff`]
              })
            })
          }
        })

        data.fields.partner_custom = JSON.stringify(formatData);
      }

      data.company_id = user_id

      const params = { endpoint: '/users', data }

      const updateClient = await Api(params)

      notification({ variant: updateClient.success ? 'success' : 'error', message: updateClient.message })
      setErrors({})
    }
  }

  const handleDeleteUser = async () => {
    const params = {
      endpoint: `/users`,
      method: 'DELETE',
      data: {
        company_id: user_id,
      },
    };

    const deleteUser = await Api(params);

    if (deleteUser.success) {
      notification({ variant: deleteUser.success ? 'success' : 'error', message: deleteUser.message })
      navigate('/');
    }
  }

  // 
  // ─── HANDLE DISABLED UPDATE ───────────────────────────────────────
  //
  const handleDisabledUpdate = async () => {
    const data = { fields: { disabled: !user.disabled }, company_id: user.user_id };
    const params = { endpoint: '/users', data }

    const updateClient = await Api(params)

    const message = updateClient.success ? Boolean(user.disabled) ? "L'utilisateur n'est plus banni" : 'Utilisateur banni' : 'Erreur interne'
    notification({ variant: updateClient.success ? 'success' : 'error', message })

    updateClient.success && setUser({ ...user, disabled: !user.disabled })
  }

  // 
  // ─── HANDLE PASSWORD UPDATE ───────────────────────────────────────
  //
  const handlePassword = async () => {
    const data = prepareFormData({ formId: 'password' });
    let password = getValue(data, ['fields', 'password'])
    let password_repeat = getValue(data, ['fields', 'password_repeat'])

    if (data.errors) {
      setErrors({ ...errors, 'password': { ...data.errors } })
    } else {
      // Check if passwords are the same
      if (password === password_repeat) {
        delete data.fields.password_repeat
        data.update_password = true

        const params = { endpoint: '/users', data }
        const updatePassword = await Api(params)

        notification({ variant: updatePassword.success ? 'success' : 'error', message: updatePassword.message })
        setErrors({ ...errors, 'password': {} })
      } else {
        setErrors({ ...errors, 'password': { password_repeat: { error: true, message: 'Les mots de passe ne sont pas identiques' } } })
      }
    }
  }

  // 
  // ─── DELETE FILE ───────────────────────────────────────
  //
  const deleteFile = async (opt) => {
    const data = {
      name: opt.name,
      filename: opt.filename,
    }

    const params = {
      endpoint: `/users/file`,
      method: "DELETE",
      data
    }

    const removeFile = await Api(params)

    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (removeFile.success) {
      delete user[opt.name];
      setUser({ ...user });
    }
    notification({ variant: removeFile.success ? 'success' : 'error', message: removeFile.message })
  }

  // 
  // ─── DONWLOAD FILE ───────────────────────────────────────
  //
  const downloadFile = async (opt) => {
    const params = {
      endpoint: `/users/file?name=${opt.name}&filename=${opt.filename}&user_id=${user_id}`,
      method: "GET",
    }

    const getFile = await Api(params);

    // download file management
    const link = document.createElement('a');
    link.href = `data:${getFile.mimetype};base64,` + getFile.data;
    link.download = opt.filename;
    link.click();
  }

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (event) => {
    const element = event.target
    let filename
    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (element.files[0]) {
      filename = `${element.name}-${element.files[0].name}`;
      formData.append('file', element.files[0], filename);
    }
    formData.append('name', event.target.name)
    formData.append('user_id', user.user_id)

    // 
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/users/file',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);

    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (addFile.success) {
      setUser({ ...user, ...{ [element.name]: element.files[0].name } });
    }
    notification({ variant: addFile.success ? 'success' : 'error', message: addFile.message })
  }

  const handlePartner = (e) => {
    setPartner(e)
  }

  const companyHours = '{ "0": { "label": "Lundi", "value": "" }, "1": { "label": "Mardi", "value": "" }, "3": { "label": "Mercredi", "value": "" }, "4": { "label": "Jeudi", "value": "" }, "5": { "label": "Vendredi", "value": "" }, "6": { "label": "Samedi", "value": "" },"7": { "label": "Dimanche", "value": "" }}';

  // 
  // ─── FORMES FIELDS ───────────────────────────────────────
  //
  const isDriver = user.user_type === 1;
  const formFields = [
    {
      title: 'Informations personnelles',
      form: 'personal_infos',
      fields: [
        { type: "text", component: "text", name: "firstname", label: "Prénom", default: user.firstname, size: 'calc(50% - 10px)', required: true, cond: isDriver },
        { type: "text", component: "text", name: "name", label: "Nom", default: user.name, size: 'calc(50% - 10px)', required: true, cond: isDriver },
        { type: "text", component: "text", name: "company", label: "Nom de la société", default: user.company, required: true, cond: !isDriver },
        { type: "text", component: "text", name: "phone", label: "Numéro de téléphone", default: user.phone, required: true },
        { type: "email", component: "text", name: "email", label: "Email", default: user.email, required: true },
        { type: "text", component: "text", name: "address", label: "Adresse", default: user.address, required: true },
        { type: "text", component: "text", name: "cp", label: "Code postal", default: user.cp, size: 'calc(50% - 10px)', required: true },
        { type: "text", component: "text", name: "city", label: "Ville", default: user.city, size: 'calc(50% - 10px)', required: true },
        { type: "text", component: "text", name: "siret", label: "Numéro de siret", default: user.siret, required: true, cond: !isDriver },
        {
          type: "text", component: "text", name: "facebook_page_id", label: "Identifiant de la page facebook", default: user.facebook_page_id, cond: !isDriver, help: (
            <div style={{ fontSize: '12px', marginTop: '5px' }}>
              <p style={{ marginBottom: '3px' }}>Pour trouver l’ID de votre Page :</p>
              <ol style={{ paddingLeft: '25px', li: { fontWeight: 'bold' } }}>
                <li>
                  Dans votre fil, cliquez sur <strong>Pages</strong> dans le menu de gauche.
                </li>
                <li>Cliquez sur le nom de votre Page pour y accéder.</li>
                <li>
                  Cliquez sur <strong>À propos</strong> en haut de votre Page. Si vous ne voyez pas
                  cette option, cliquez sur <strong>Plus</strong>.
                </li>
                <li>
                  Faites défiler la page vers le bas pour trouver{' '}
                  <strong>l’ID de votre Page </strong>en dessous de la section{' '}
                  <strong>Plus d’informations</strong>.
                </li>
              </ol>
            </div>
          ),
        },
        { type: "text", component: "multiple", name: "company_hours", default: user.company_hours || companyHours, label: "Horaires d'ouverture", titles: { label: "", value: "" }, removeAdd: true, cond: !isDriver },
        { type: "hidden", component: "text", name: "partner", default: partner, hidden: true, cond: canPartner },
      ],
      action: () => handlePersonalInfos('personal_infos')
    },
    {
      title: 'Modification du mot de passe',
      form: 'password',
      fields: [
        { type: "password", component: "text", name: "password_old", label: "Mot de passe actuel", required: true },
        { type: "password", component: "text", name: "password", label: "Nouveau mot de passe", required: true },
        { type: "password", component: "text", name: "password_repeat", label: "Répéter le mot de passe", required: true },
      ],
      action: handlePassword
    },
    {
      title: 'Permis de conduire',
      form: 'driver_licence',
      fields: [
        { type: "text", component: "text", name: "driver_licence_id", label: "Numéro du permis de conduire", required: true, default: user.driver_licence_id },
        { type: "text", component: "text", name: "driver_licence_place", label: "Lieu d'obtention", required: true, default: user.driver_licence_place },
        { type: "text", component: "text", name: "driver_licence_prefecture", label: "Préfecture ayant délivré le permis", required: true, default: user.driver_licence_prefecture },
      ],
      action: () => handlePersonalInfos('driver_licence'),
      cond: props.role === 'driver'
    }
  ];

  const fileFields = {
    title: 'Télécharger des fichiers',
    form: 'upload_files',
    fields: [
      { type: "text", component: "file", name: "driver_license_file", label: "Permis de conduire", size: 'calc(50% - 10px)' },
      { type: "text", component: "file", name: "assurance_file", label: "Assurance", size: 'calc(50% - 10px)' },
      { type: "text", component: "file", name: "car_registration_file", label: "Carte d'identité", size: 'calc(50% - 10px)' },
      { type: "text", component: "file", name: "civil_liability_file", label: "Responsabilité civile", size: 'calc(50% - 10px)' },
      { type: "text", component: "file", name: "rib_file", label: "RIB", size: 'calc(50% - 10px)' },
      { type: "text", component: "file", name: "criminal_record_file", label: "Contrat signé", size: 'calc(50% - 10px)' },
    ]
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      maxWidth: 800,
      margin: "20px auto",
      padding: 20
    },
    form: {
      maxWidth: 800,
      margin: "20px 0",
      background: "#FFF",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    title: {
      marginTop: 40
    },
    btn: {
      marginLeft: 'auto !important'
    },
    fileUploaded: {
      width: 'calc(50% - 10px)',
      display: 'flex',
      alignItems: 'center'
    },
    fileContent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
      marginRight: 'auto'
    },
    fileIcon: {
      fontSize: '35px !important',
      color: 'var(--primary-color)'
    },
    fileLabel: {
      marginBottom: 5
    },
    fileName: {
      fontSize: 13,
      width: 250,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    superAdminContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      marginBottom: 20
    },
    personalizedContainer: {
      background: "#FFF",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: '10px',
      justifyContent: 'space-between',
      marginTop: 20,

      '& h3': {
        fontSize: '17px',
        marginBottom: 0,

        '&:first-child': {
          marginTop: 0
        }
      }
    },
    personalizedCategory: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      justifyContent: 'space-between',
      marginTop: 15,
    },
    personalizedRow: {
      display: 'flex',
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.container}>
      <>
        {user.user_type === 2 && <Formfields field={{
          component: "select", name: "partner", label: 'Partenaire', dataValue: 'id', default: partner, data: Object.values(window._DATA['partner']).map((type) => {
            return { id: type.value, name: type.label }
          }),
          handleChange: handlePartner,
          cond: props.role === 'super-admin'
        }} />}
        {user.user_type === 2 && Boolean([1, 4].includes(partner)) && (
          <form className={classes.personalizedContainer} id='personalized-form'>
            <p>Lors du clique sur le bouton, le champ ne sera plus enregistré en tant que prix mais en tant que ratio. Il sera appliqué sur la distance pour déterminer le prix (ex : 1.1 x distance)</p>
            <Formfields field={{ default: (user.partner_custom && user.partner_custom.recovery) || '40', component: "text", name: "recovery", label: 'Remise retour (%)', size: '150px', cond: props.role === 'super-admin' }} />
            {
              vehicles.map((cat, i) => (
                <React.Fragment key={cat}>
                  <h3>{cat}</h3>
                  {Boolean(partner === 1) && <Formfields field={{ default: user.partner_custom ? user.partner_custom[i + 1].promo : '', component: "text", name: `${cat}-promo`, label: 'Remise (%)', size: '150px', cond: props.role === 'super-admin' }} />}
                  {Boolean(partner === 4) && <div className={classes.personalizedCategory}>
                    {
                      personalizedFields.map((field, n) =>
                        <div key={`${cat}-${field}`} className={classes.personalizedRow}>
                          <Formfields field={{ component: 'switch', name: `${cat}-${field}-coeff`, default: (user.partner_custom && Object.keys(user.partner_custom[`${i + 1}`]).length > 1 && user.partner_custom[`${i + 1}`]?.[n]?.coeff) ? 1 : 0, size: '50px', required: true, error: getValue(errors, [`${cat}-${field}-coeff`, 'message']) }} />
                          <Formfields field={{ component: 'text', type: 'text', name: `${cat}-${field}`, default: (user.partner_custom && Object.keys(user.partner_custom[`${i + 1}`]).length > 1) ? user.partner_custom[i + 1][n].value : '', label: field, size: '150px', required: true, error: getValue(errors, [`${cat}-${field}`, 'message']) }} />
                        </div>
                      )
                    }
                  </div>}
                </React.Fragment>
              )
              )
            }
          </form>
        )}
      </>
      {Boolean(Object.keys(user).length) && formFields.map((element, index) => {
        const checkCond = element.cond || !element.hasOwnProperty('cond')
        return checkCond && <div key={element.form}>
          <h2 className={classes.title}>{element.title}</h2>
          <form className={classes.form} name={element.form}>
            {element.fields.map((field) => {
              const checkCond = field.cond || !field.hasOwnProperty('cond')
              return checkCond && <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, [element.form, field.name, 'message']) } }} />
            })}
            {element.action && index === 0 && props.role === 'super-admin' && < Button color="error" variant="outlined" className={classes.btn} onClick={() => { setOpen(true) }}>Supprimer</Button>}
            {element.action && index === 0 && props.role === 'super-admin' && < Button color="error" variant="contained" onClick={handleDisabledUpdate}>{Boolean(user.disabled) ? 'Annuler le bannissement' : 'Bannir'}</Button>}
            {element.action && <Button variant="contained" onClick={element.action}>Sauvegarder</Button>}
          </form>
        </div>
      })}
      {isDriver && <>
        <h2 className={classes.title}>{fileFields.title}</h2>
        <form className={classes.form} name={fileFields.form}>
          {Boolean(Object.keys(user).length) && fileFields.fields.map((field) => {
            if (user[field.name]) {
              return <div className={classes.fileUploaded}>
                <InsertDriveFileIcon className={classes.fileIcon} />
                <div className={classes.fileContent}>
                  <span className={classes.fileLabel}>{field.label}</span>
                  <span className={classes.fileName}>{user[field.name]}</span>
                </div>
                <IconButton aria-label="download" onClick={() => downloadFile({ name: field.name, filename: user[field.name] })}>
                  <DownloadIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => deleteFile({ name: field.name, filename: user[field.name] })}>
                  <DeleteIcon />
                </IconButton>
              </div>
            } else {
              return <Formfields key={field.name} field={{ ...field, ...{ handleChange: uploadFile, error: getValue(errors, [fileFields.form, field.name, 'message']) } }} />
            }
          })}
          {fileFields.action && <Button variant="contained" className={classes.btn} onClick={fileFields.action}>Sauvegarder</Button>}
        </form>
      </>}
    </div>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Êtes-vous sur de vouloir supprimer l'utilisateur ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Attention, cette action est irréversible et l'utilisateur ne pourra pas être restauré.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Annuler</Button>
        <Button
          color="error" variant="contained"
          onClick={handleDeleteUser}
          autoFocus
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default UserDetail;