// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Api from '../contexts/Api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { formatDate, prepareFormData, getValue } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import { PenaltiesList } from '../contexts/Contexts';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Formfields from '../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const PenaltyList = (props) => {
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const [loaded, setLoaded] = useState(false);
  const [penalties, setPenalties] = useState([]);
  const [errors, setErrors] = useState({});
  const [dialog, setDialog] = useState({ open: false });
  const { notification } = useNotification();
  const role = props.role;

  // 
  // ─── GENERATE DISPLAY PRICE ───────────────────────────────────────
  //
  const genPenaltyPrice = (id) => {
    let price = 0;

    PenaltiesList.forEach(e => {
      if (penalties[id][e.name]) {
        price += parseInt(penalties[id][e.name]);
      }
    })

    return price;
  }

  // 
  // ─── HANDLE DIALOG OPEN AND CLOSE ───────────────────────────────────────
  //
  const handleClickOpen = (e, opt) => {
    e.stopPropagation();

    setDialog({ open: true, ...opt });
  };

  const handleClose = () => {
    setDialog({ open: false });
  };

  // 
  // ─── HANDLE DIALOG UPDATE PENALTY STATUS ───────────────────────────────────────
  //
  const handlePenalty = async () => {
    let data = prepareFormData({ formId: 'status' }) || {};

    if (data.errors) {
      setErrors(data.errors);
      return;
    }

    data.penalty_id = dialog.id

    // set unchecked to 0€
    PenaltiesList.forEach(e => {
      if (!(e.name in data.fields)) {
        data.fields[e.name] = '0'
      }
    })

    const params = { endpoint: '/penalties', method: 'PUT', data }
    const updatePenalty = await Api(params)

    notification({ variant: updatePenalty.success ? 'success' : 'error', message: updatePenalty.message })

    if (updatePenalty.success) {
      handleClose();
      const updatePenalties = { ...penalties };
      updatePenalties[dialog.id] = { ...penalties[dialog.id], ...data.fields };

      setPenalties({ ...updatePenalties })
    }
  }

  // 
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchMissions = async () => {

      const getPenalty = await Api({
        endpoint: `/penalties?user_id=${user_id}${role === 'driver' ? `&driver_id=${user_id}` : ''}${['garage', 'super-admin'].includes(role) ? `&company_id=${role === 'garage' ? user_id : 'all'}` : ''}`,
        method: "GET"
      });
      setLoaded(true);

      if (getPenalty.success && getPenalty.data.length) {
        let data = {}
        getPenalty.data.forEach(e => {
          data[e.penalty_id] = e;
        })

        setPenalties(data)
      } else {
        setPenalties([])
      }
    }
    fetchMissions()
  }, [user_id, role])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      margin: '0 auto',
      maxWidth: 900,
      padding: 20,

      '& .MuiAccordion-root': {
        boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
        background: "#FFF",
        padding: '20px 20px 15px',
        marginBottom: 20,
        flexDirection: 'column',

        '&::before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
          alignItems: 'flex-end',
          paddingBottom: 12,

          '& .MuiAccordionSummary-content': {
            marginBottom: 0,
          }
        }
      }
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    penaltyView: {
      '& p:first-child': {
        color: '#9B9B9B',
        fontSize: '14px',

        '& span': {
          marginLeft: '10px',
          color: '#000'
        }
      },
    },
    penaltyInfos: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media screen and (max-width:600px)': {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '0 20px',
        fontSize: 14
      }
    },
    penaltyDriver: {
      color: 'var(--light-grey)',
      fontSize: '0.9em',
      marginBottom: 10
    },
    value: {
      color: 'var(--primary-color)'
    },
    dialogContent: {
      paddingTop: '20px !important',

      '& .formfield_container:first-child': {
        marginBottom: 20
      }
    },
    noResult: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 'auto',
      '& img': {
        width: 400,
        margin: 'auto'
      },
      '& span': {
        textAlign: 'center',
        marginTop: 10,
        fontSize: '1.1em'
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    {loaded ? <>
      {penalties ? Object.values(penalties).map((penalty) =>
        <Accordion key={penalty.penalty_id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ 'MuiAccordionSummary-content': { alignItems: 'center' } }}>
            <div className={classes.accordionSummaryContent}>
              {role !== 'driver' && <div className={classes.penaltyDriver}>{`${penalty.name} ${penalty.firstname} (${penalty.email})`}</div>}
              <div className={classes.penaltyInfos}>
                <div>N°<span className={classes.value}>{penalty.mission_ref}</span></div>
                <div>Pénalité : <span className={classes.value}>{`${genPenaltyPrice(penalty.penalty_id)}€`}</span></div>
                <div>Statut : <span className={classes.value}>{getValue(window, ['_DATA', 'penalty_status', penalty.status, 'label'])}</span></div>
                <div>Date : <span className={classes.value}>{formatDate({ time: penalty.time_create, display: 'date' })}</span></div>
                {role !== 'driver' && <div>
                  <IconButton size='small' aria-label="delete" onClick={(e) => handleClickOpen(e, { status: penalty.status, id: penalty.penalty_id })}>
                    <EditIcon />
                  </IconButton>
                </div>}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.penaltiesView}>
              {
                PenaltiesList.map(e => (
                  <div className={classes.penaltyView} key={e.name}>
                    <p>{e.label} <span>{penalty[e.name] || 0}€</span></p>
                  </div>
                ))
              }
            </div>
          </AccordionDetails>
        </Accordion>)
        :
        <div className={classes.noResult}>
          <img alt='Aucun résultat' src='/assets/img/no_result.svg' />
          <span>Aucun résultat</span>
        </div>}
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Modifier la pénalité</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form name='status'>
            <Formfields field={{
              component: "select", name: "status", label: 'Statut de la pénalité', default: dialog.status, data: Object.values(window._DATA['penalty_status']).map((type) => {
                return { id: type.value, name: type.label }
              }),
            }} />
            {PenaltiesList.map(e => <Formfields key={e.name} field={{ component: 'switch', textName: e.name, label: e.label, size: '100%', textfield: true, textLabel: "Montant (€)", default: ('id' in dialog && penalties[dialog.id][e.name]) || 0, textValue: 'id' in dialog && penalties[dialog.id][e.name], required: true, textError: getValue(errors, [`${e.name}`, 'message']) }} />)}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error'>Annuler</Button>
          <Button onClick={handlePenalty} variant='contained' autoFocus>Valider</Button>
        </DialogActions>
      </Dialog>
    </> : <div>Chargement</div>}
  </div>
};

export default PenaltyList;